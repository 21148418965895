<template>
    <v-card>
        <v-card-title>
            <div class="text-h4 px-4">所有訂購申請</div>
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
            ></v-text-field>
        </v-card-title>
        <v-data-table
                :headers="headers"
                :items="group_ask"
                :search="search"
                class="elevation-1"
                :loading="loading"
                loading-text="Loading... Please wait"
                :options.sync="options"
                :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-chevron-double-left',
                lastIcon: 'mdi-chevron-double-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus'
            }"
        >
            <template v-slot:item.is_closed="{ item }">
                <v-switch disabled v-model="item.is_closed" color="green darken-3"></v-switch>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-icon
                        large
                        class="mr-8"
                        @click="editTranAsk(item.id)"
                        color="primary"
                >
                    mdi-file-document-edit-outline
                </v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
                loading: true,
                search: '',
                options: {
                    itemsPerPage: 10,
                },
                headers: [
                    { text: 'Group Ask ID', value: 'id', class: 'text-subtitle-1' },
                    { text: 'Create Date', value: 'created_at', class: 'text-subtitle-1' },
                    { text: 'Group ID', value: 'group_id', class: 'text-subtitle-1' },
                    { text: 'Group Name', value: 'group_name', class: 'text-subtitle-1' },
                    { text: 'Mobile', value: 'mobile', class: 'text-subtitle-1' },
                    { text: 'Remarks', value: 'remarks', class: 'text-subtitle-1' },
                    { text: 'Closed', value: 'is_closed', class: 'text-subtitle-1' },
                    { text: 'Updated By', value: 'updated_by', class: 'text-subtitle-1' },
                    { text: 'Updated Date', value: 'updated_at', class: 'text-subtitle-1' },
                    { text: 'Actions', value: 'actions', class: 'text-subtitle-1', sortable: false },
                ],
                group_ask: [],
            }
        },
        created() {
            this.axios
                .get('/api/group_ask')
                .then(response => {
                    this.loading = false;
                    this.group_ask = response.data.data;
                });
        },
        methods: {
            editTranAsk(id) {
                this.$router.push('/group_ask/'+id+'/edit');
            },
        }
    }
</script>