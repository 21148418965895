<template>
    <v-card>
        <v-card-title>
            <div class="text-h4 px-4">所有團管理</div>
            <v-btn color="primary" dark x-large class="mx-4 text-h4" router :to="{name:'AddGroup'}">我要開新團</v-btn>
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
            ></v-text-field>
        </v-card-title>
        <v-data-table
                :headers="headers"
                :items="groups"
                :search="search"
                class="elevation-1"
                :loading="loading"
                loading-text="Loading... Please wait"
                show-expand
                :options.sync="options"
                :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-chevron-double-left',
                lastIcon: 'mdi-chevron-double-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus'
            }"
        >
            <template v-slot:item.suspend="{ item }">
                <v-chip class="ma-2 text-h6" color="red" label dark v-if=item.suspend>
                    <v-icon left>mdi-cart-off</v-icon>
                    停團
                </v-chip>
                <v-chip class="ma-2 text-h6" color="green" label dark v-else>
                    <v-icon left>mdi-cart</v-icon>
                    開團中
                </v-chip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="text-subtitle-1 pa-4">
                    Description: {{ item.description }}<br>
                    Currency: {{ item.currency }}<br>
                    Remarks: {{ item.remarks }}<br>
                    {{checkout_site}}/group/{{ item.id }}<br>
                </td>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                        large
                        class="mr-4"
                        @click="editGroup(item.id)"
                        color="primary"
                >
                    mdi-file-document-edit-outline
                </v-icon>
                <v-icon
                        large
                        class="mr-4"
                        @click="editGroupPhoto(item.id)"
                        color="primary"
                >
                    mdi-image
                </v-icon>
                <v-icon
                        large
                        class="mr-4"
                        @click="showOneGroup(item.id)"
                        color="primary"
                >
                    mdi-file-document
                </v-icon>
                <v-icon
                        large
                        class="mr-4"
                        @click="showTran(item.id)"
                        color="primary"
                >
                    mdi-currency-usd
                </v-icon>
                <v-icon
                        large
                        class="mr-4"
                        @click="addTran(item.id)"
                        color="primary"
                >
                    mdi-basket-plus
                </v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
                loading: true,
                search: '',
                options: {
                    itemsPerPage: 10,
                },
                headers: [
                    {text: 'Group ID', value: 'id', class: 'text-subtitle-1'},
                    {text: 'Name', value: 'name', class: 'text-subtitle-1'},
                    {text: 'Start Date', value: 'start_date', class: 'text-subtitle-1'},
                    {text: 'Expiry Date', value: 'expiry_date', class: 'text-subtitle-1'},
                    {text: 'Type', value: 'type', class: 'text-subtitle-1'},
                    {text: 'Suspend', value: 'suspend', class: 'text-subtitle-1'},
                    {text: 'Updated By', value: 'updated_by', class: 'text-subtitle-1'},
                    {text: 'Actions', value: 'actions', class: 'text-subtitle-1', sortable: false},
                ],
                groups: [],
                group: {},
                dialog: false,
                valid: false,
                firstname: '',
                lastname: '',
                nameRules: [
                    v => !!v || 'Name is required',
                    v => v.length <= 100 || 'Name must be less than 100 characters',
                ],
                email: '',
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+/.test(v) || 'E-mail must be valid',
                ],
                date: new Date().toISOString(),
                menu: false,
                suspend: 1,
                checkout_site: this.$store.state.checkout_site,
            }
        },
        created() {
            // if (Vue.prototype.$islogin){
            this.axios
                .get('/api/group')
                .then(response => {
                    this.loading = false;
                    this.groups = response.data.data;
                });
            // }
            // else{
            //     this.$router.push(Vue.prototype.$loginpage);
            // }
        },
        methods: {
            addGroup() {
                this.axios
                    .post('/api/group', this.group)
                    .then(response => {
                        this.$router.push({name: 'AddGroup'});
                        console.log(response);
                    });
            },
            editGroup(id) {
                this.$router.push('/group/' + id + '/edit');
            },
            editGroupPhoto(id) {
                this.$router.push('/group_photo/' + id + '/edit');
            },
            showOneGroup(id) {
                this.$router.push('/group/' + id);
            },
            showTran(id) {
                this.$router.push('/tran/' + id + '/search_tran_by_group_id');
            },
            addTran(id) {
                this.$router.push('/buy/' + id + '/show');
            },
        }
    }
</script>

<style>
</style>