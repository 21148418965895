<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12">
                <v-card
                        class="mx-auto my-4"
                >
                    <v-card-title>
                        <div class="text-h4 mx-4">查詢訂單 (客人電話)</div>
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table>
                            <template v-slot:default>
                                <tbody>
                                <tr>
                                    <td width="20%"><div class="text-h6">Country Calling Codes</div></td>
                                    <td><div class="text-h6">
                                        <v-select
                                                :items="country_calling_codes"
                                                item-text="name"
                                                outlined
                                                placeholder="請選擇"
                                                item-value="country_calling_codes"
                                                v-model="selected_country_calling_codes"
                                                class="mt-4"
                                        ></v-select></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><div class="text-h6">Mobile</div></td>
                                    <td><div class="text-h6">
                                        <v-text-field
                                                outlined
                                                label="電話號碼"
                                                prepend-inner-icon="mdi-phone"
                                                v-model="mobile"
                                                @change="inputMobile"
                                                class="mt-4"
                                        ></v-text-field></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><v-btn x-large color="primary" class="ma-4 float-right" dark @click.prevent="SearchTran"><div class="text-h5">查詢訂單</div></v-btn></td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                country_calling_codes: ['+853','+852','+86'],
                selected_country_calling_codes: '+853',
                mobile: '',
            }
        },
        created() {
        },
        methods: {
            SearchTran() {
                this.mobile = this.selected_country_calling_codes+this.mobile;
                this.$router.push('/tran/'+this.mobile+'/search_tran_by_mobile1/');
            },
            inputMobile(){
                this.mobile = this.mobile.replace(/[^0-9#]/g,'');
            },
        }
    }
</script>