<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12">
                <v-card>
                    <v-chip class="ma-4" color="green" text-color="white">
                        <div class="text-h5">購買商品</div>
                        <v-avatar right class="green darken-4">
                            {{ product_total }}
                        </v-avatar>
                    </v-chip>
                    <div class="pa-4">
                        <ul>
                            <li class="text-h5" v-for="item1 in products" :key="item1.id">
                                {{ item1.full_item_code }} | {{ item1.item_name}}-{{ item1.att }} | {{ tran.currency }} {{ item1.price }} | {{ item1.qty }} <v-btn x-large color="primary" class="ma-2" dark @click.prevent="updateItemRemarks(item1.id, item1.remarks)"><div class="text-h5">更新商品備註</div></v-btn>
                                <div v-if="remarks_show" class="red pa-2">{{ remarks_update_message }}</div>
                                <div class="text-h6 mb-2"><v-textarea
                                        solo
                                        name="input-7-4"
                                        label="Remarks"
                                        v-model="item1.remarks"
                                ></v-textarea></div>
                            </li>
                        </ul>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <v-card
                        class="mx-auto mb-4"
                >
                    <v-card-title>
                        <div class="text-h4 mx-4">Transaction No. {{ tran.id }}</div>
                        <v-btn x-large color="primary" class="mx-4" dark @click.prevent="updateTran()"><div class="text-h5">儲存更新</div></v-btn>
                        <v-btn x-large color="primary" class="mx-4" dark @click.prevent="back"><div class="text-h5">返回</div></v-btn>
                    </v-card-title>

                    <v-card-text class="mb-n6">
                        <v-simple-table>
                            <template v-slot:default>
                                <tbody>
                                <tr>
                                    <td><div class="text-h6">Status</div></td>
                                    <td><div class="text-h6"><v-select item-text="name" item-value="id" :items="status_list" v-model="tran.transaction_status_list_id" outlined class="mt-4"></v-select></div></td>
                                </tr>
                                <tr>
                                    <td><div class="text-h6">Notice</div></td>
                                    <td><v-switch v-model="tran.is_notice" label="Notice to customer" color="green darken-3"></v-switch></td>
                                </tr>
                                <tr>
                                    <td><div class="text-h6">Remarks (Internal only)</div></td>
                                    <td><div class="text-h6 mt-4"><v-textarea
                                            solo
                                            name="input-7-4"
                                            label="Remarks"
                                            v-model="tran.remarks"
                                    ></v-textarea></div></td>
                                </tr>
                                <tr>
                                    <td><div class="text-h6">Invoice</div></td>
                                    <td><div class="text-h6">{{ tran.invoice }}</div></td>
                                </tr>
                                <tr>
                                    <td><div class="text-h6">Order Date</div></td>
                                    <td><div class="text-h6">{{ tran.created_at }}</div></td>
                                </tr>
                                <tr>
                                    <td><div class="text-h6">Group ID</div></td>
                                    <td><div class="text-h6">{{ tran.group_id }}</div></td>
                                </tr>
                                <tr>
                                    <td><div class="text-h6">Group Name</div></td>
                                    <td><div class="text-h6">{{ tran.group_name }}</div></td>
                                </tr>
                                <tr>
                                    <td><div class="text-h6">Country calling codes</div></td>
                                    <td><div class="text-h6">{{ tran.country_calling_codes }}</div></td>
                                </tr>
                                <tr>
                                    <td><div class="text-h6">Mobile</div></td>
                                    <td><div class="text-h6">{{ tran.org_mobile }}</div></td>
                                </tr>
                                <tr>
                                    <td><div class="text-h6">Source</div></td>
                                    <td><div class="text-h6">{{ tran.source }}</div></td>
                                </tr>
                                <tr>
                                    <td><div class="text-h6">Facebook Full Name</div></td>
                                    <td><div class="text-h6">{{ tran.fb_full_name }}</div></td>
                                </tr>
                                <tr>
                                    <td><div class="text-h6">Sex</div></td>
                                    <td><div class="text-h6">{{ tran.sex }}</div></td>
                                </tr>
                                <tr>
                                    <td><div class="text-h6">Facebook UID</div></td>
                                    <td><div class="text-h6">{{ tran.fb_uid }}</div></td>
                                </tr>
                                <tr>
                                    <td><div class="text-h6">Amount</div></td>
                                    <td><div class="text-h6">{{ tran.currency }} {{ tran.amount }}</div></td>
                                </tr>
                                <tr>
                                    <td><div class="text-h6">Payment</div></td>
                                    <td><div class="text-h6">{{ tran.payment_list_name }}</div></td>
                                </tr>
                                <tr>
                                    <td><div class="text-h6">Delivery</div></td>
                                    <td><div class="text-h6">{{ tran.delivery_list_name }}</div></td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <v-card class="elevation-0 my-4">
                    <v-card-title><div class="text-h5 mx-4">訂單狀態記錄 ({{ status.length }})</div></v-card-title>
                    <template>
                        <v-timeline dense>
                            <v-timeline-item v-for="item2 in status" :key="item2.id" :color="timeline_color(item2.status_group)">
                                <v-card class="elevation-2">
                                    <v-card-title class="headline">{{ item2.name }} ---> {{ item2.cname}}</v-card-title>
                                    <v-card-text>
                                        <div class="text-h6">{{ item2.updated_at }}, {{ item2.updated_by }}</div>
                                    </v-card-text>
                                </v-card>
                            </v-timeline-item>
                        </v-timeline>
                    </template>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                tran: [],
                products: [],
                status: [],
                product_total: 0,
                status_list: [],
                tran_payment: [],
                photo_url: '',
                remarks_show: false,
                remarks_update_message: '',
            }
        },
        created() {
            this.axios
                .get("/api/tran/"+this.$route.params.id)
                .then((response) => {
                    this.tran = response.data.data[0];
                });
            this.axios
                .get("/api/tran_product/"+this.$route.params.id)
                .then((response) => {
                    this.products = response.data.data;
                    this.product_total = this.products.reduce((acc, item) => acc + item.qty, 0);
                });
            this.axios
                .get("/api/tran_status/"+this.$route.params.id)
                .then((response) => {
                    this.status = response.data.data;
                    // for (let i=0; i<this.status.length; i++){
                    //     if(this.status[i].transaction_status_id == 2) {
                    //         this.axios
                    //             .get("/api/tran_payment/"+this.$route.params.id+"/search_tran_payment_by_tran_id")
                    //             .then((response)=>{
                    //                 this.tran_payment = response.data.data[0];
                    //             });
                    //     }
                    // }
                });
            this.axios
                .get("/api/tran_status_list")
                .then((response) => {
                    this.status_list = response.data.data;
                });
        },
        methods: {
            timeline_color(id) {
                if (id === 1)
                    return 'green';
                else if (id === 2)
                    return 'blue';
                else if (id === 3)
                    return 'lime';
                else if (id === 4)
                    return 'purple';
                else if (id === 5)
                    return 'red';
                else if (id === 6)
                    return 'black';
                else
                    return 'green';
            },
            back() {
                // this.$router.push('/tran/'+this.tran.group_id+'/search_tran_by_group_id');
                this.$router.go(-1);
            },
            updateTran() {
                this.axios
                    .put("/api/tran/"+this.$route.params.id, this.tran)
                    .then((response) => {
                        this.$router.go(-1);
                        // this.$router.push("/tran/"+this.$route.params.id);
                        console.log(response);
                    });
            },
            updateItemRemarks(id, remarks){
                this.axios
                    .put("/api/tran_product/"+id, {remarks: remarks})
                    .then((response) => {
                        this.remarks_show = true;
                        this.remarks_update_message = response.data.data.message;
                        console.log(response);
                    });
                console.log(remarks);
            }
        },
    }
</script>