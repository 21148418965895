<template>
            <v-container>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-card
                                class="mx-auto my-4"
                        >
                        <v-card-title>
                            <div class="text-h4 mx-4">Transaction No. {{ tran.id }}</div>
                            <v-btn x-large color="primary" class="mx-4" dark @click.prevent="edit(tran.id)"><div class="text-h5">編輯訂單</div></v-btn>
                            <v-btn x-large color="primary" class="mx-4" dark @click.prevent="back"><div class="text-h5">返回</div></v-btn>
                        </v-card-title>

                        <v-card-text class="mb-n6">
                        <v-simple-table>
                                <template v-slot:default>
                                    <tbody>
                                    <tr>
                                        <td><div class="text-h6">Invoice</div></td>
                                        <td><div class="text-h6">{{ tran.invoice }}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div class="text-h6">Order Date</div></td>
                                        <td><div class="text-h6">{{ tran.created_at }}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div class="text-h6">Group ID</div></td>
                                        <td><div class="text-h6">{{ tran.group_id }}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div class="text-h6">Group Name</div></td>
                                        <td><div class="text-h6">{{ tran.group_name }}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div class="text-h6">Country calling codes</div></td>
                                        <td><div class="text-h6">{{ tran.country_calling_codes }}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div class="text-h6">Mobile</div></td>
                                        <td><div class="text-h6">{{ tran.org_mobile }}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div class="text-h6">Source</div></td>
                                        <td><div class="text-h6">{{ tran.source }}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div class="text-h6">Facebook Full Name</div></td>
                                        <td><div class="text-h6">{{ tran.fb_full_name }}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div class="text-h6">Sex</div></td>
                                        <td><div class="text-h6">{{ tran.sex }}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div class="text-h6">Facebook UID</div></td>
                                        <td><div class="text-h6">{{ tran.fb_uid }}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div class="text-h6">Amount</div></td>
                                        <td><div class="text-h6">{{ tran.currency }} {{ tran.amount }}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div class="text-h6">Payment</div></td>
                                        <td><div class="text-h6">{{ tran.payment_list_name }}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div class="text-h6">Delivery</div></td>
                                        <td><div class="text-h6">{{ tran.delivery_list_name }}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div class="text-h6">Status</div></td>
                                        <td><div class="text-h6">{{ tran.transaction_status_list_name }}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div class="text-h6">Notice</div></td>
                                        <td><v-switch disabled v-model="tran.is_notice" label="Notice to customer" color="green darken-3"></v-switch></td>
                                    </tr>
                                    <tr>
                                        <td><div class="text-h6">Remarks (Internal only)</div></td>
                                        <td><div class="text-h6">{{ tran.remarks }}</div></td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                        </v-card>
                        </v-col>
                    </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-card>
                            <v-chip class="ma-4" color="green" text-color="white">
                                <div class="text-h5">購買商品</div>
                                <v-avatar right class="green darken-4">
                                    {{ product_total }}
                                </v-avatar>
                            </v-chip>
                            <div class="pa-4">
                                <ul>
                                    <li class="text-h5" v-for="item1 in products" :key="item1.full_item_code">
                                        {{ item1.full_item_code }} | {{ item1.item_name}} | {{ item1.att }} | {{ tran.currency }} {{ item1.price }} | {{ item1.qty }} | 取貨時間: {{ item1.packup_at}}<br>
                                        <div v-if="item1.remarks !== null">
                                            Product Remarks: {{ item1.remarks }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <!--<div class="pa-4"><img :src="photo_url" width="100%"></img></div>-->
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-card class="elevation-0">
                            <v-card-title><div class="text-h5 mx-4">訂單狀態記錄 ({{ status.length }})</div></v-card-title>
                            <template>
                                <v-timeline dense>
                                    <v-timeline-item v-for="item2 in status" :key="item2.id" :color="timeline_color(item2.status_group)">
                                        <v-card class="elevation-2">
                                            <v-card-title class="headline">{{ item2.name }} ---> {{ item2.cname }}</v-card-title>
                                            <v-card-text>
                                                <div class="text-h6">{{ item2.updated_at }}, {{ item2.updated_by }}</div>
                                            </v-card-text>
                                        </v-card>
                                    </v-timeline-item>
                                </v-timeline>
                            </template>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
</template>

<script>
    export default {
        data() {
            return {
                tran: [],
                products: [],
                status: [],
                product_total: 0,
                tran_payment: [],
                photo_url: '',
            }
        },
        created() {
            this.axios
                .get("/api/tran/"+this.$route.params.id)
                .then((response) => {
                    this.tran = response.data.data[0];
                });
            this.axios
                .get("/api/tran_product/"+this.$route.params.id)
                .then((response) => {
                    this.products = response.data.data;
                    this.product_total = this.products.reduce((acc, item) => acc + item.qty, 0);
                });
            this.axios
                .get("/api/tran_status/"+this.$route.params.id)
                .then((response) => {
                    this.status = response.data.data;
                    // for (let i = 0; i < this.status.length; i++) {
                    //     if (this.status[i].transaction_status_id == 2) {
                    //         this.axios
                    //             .get("/api/tran_payment/" + this.$route.params.id + "/search_tran_payment_by_tran_id")
                    //             .then((response) => {
                    //                 this.tran_payment = response.data.data[0];
                    //                 this.photo_url = "/admin/storage/" + this.tran_payment.file_name;
                    //             });
                    //     }
                    // }
                });
        },
        methods: {
            timeline_color(id) {
                if (id === 1)
                    return 'green';
                else if (id === 2)
                    return 'blue';
                else if (id === 3)
                    return 'lime';
                else if (id === 4)
                    return 'purple';
                else if (id === 5)
                    return 'red';
                else if (id === 6)
                    return 'black';
                else
                    return 'green';
            },
            edit(id) {
                this.$router.push('/tran/'+id+'/edit');
            },
            back() {
                this.$router.go(-1);
                // this.$router.push('/tran/'+this.tran.group_id+'/search_tran_by_group_id');
            },
        },
    }
</script>