<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12">
                <v-card
                        class="mx-auto my-4"
                >
                    <v-card-title>
                        <div class="text-h4 mx-4">查詢訂單 (訂單狀態)</div>
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table>
                            <template v-slot:default>
                                <tbody>
                                <tr>
                                    <td width="20%"><div class="text-h6">Start Date</div></td>
                                    <td>
                                        <div class="text-h6">
                                            <v-menu
                                                    v-model="menu1"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                            v-model="start_date"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                        v-model="start_date"
                                                        @input="menu1 = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="20%"><div class="text-h6">End Date</div></td>
                                    <td>
                                        <div class="text-h6">
                                            <v-menu
                                                    v-model="menu2"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                            v-model="end_date"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                        v-model="end_date"
                                                        @input="menu2 = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><div class="text-h6">Status</div></td>
                                    <td><div class="text-h6">
                                        <v-select item-text="name" item-value="id" :items="all_tran_status" v-model="selected_status" outlined class="mt-4"></v-select>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <v-btn x-large color="primary" class="ma-4" dark @click.prevent="ExportTran"><div class="text-h5">匯出Excel</div></v-btn>
                                    </td>
                                    <td>
                                        <v-btn x-large color="primary" class="ma-4 float-right" dark @click.prevent="SearchTran"><div class="text-h5">查詢訂單</div></v-btn>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                menu1: false,
                menu2: false,
                start_date: (new Date(new Date().setDate(new Date().getDate() - 90))).toISOString().substr(0, 10),
                end_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                all_tran_status: [],
                selected_status: 3,
            }
        },
        created() {
            this.axios
                .get('/api/tran_status_list')
                .then(response => {
                    for (let i=0; i<response.data.data.length; i++) {
                        this.all_tran_status.push(response.data.data[i]);
                    }
                });
        },
        methods: {
            SearchTran() {
                this.$router.push({
                    name: 'SearchTransactionByStatus1',
                    params: { start_date: this.start_date, end_date: this.end_date, status_list_id: this.selected_status }
                });
            },
            ExportTran() {
                window.location.href = '/excel/export?tran_start_date='+this.start_date+'&tran_end_date='+this.end_date+'&tran_status_id='+this.selected_status;
            },
        }
    }
</script>