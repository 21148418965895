<template>
    <v-card>
        <v-card-title>
            <div class="text-h4 px-4">團的圖片</div>
            <v-btn color="primary" dark x-large class="mx-4 text-h4" @click.prevent="updateGroupPV">儲存更新</v-btn>
            <v-btn color="primary" dark x-large class="mx-4 text-h4" @click.prevent="back">返回</v-btn>
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
            ></v-text-field>
        </v-card-title>
        <v-data-table
                :headers="headers"
                :items="groups"
                :search="search"
                class="elevation-1"
                :loading="loading"
                loading-text="Loading... Please wait"
                :options.sync="options"
                :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-chevron-double-left',
                lastIcon: 'mdi-chevron-double-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus'
            }"
        >
            <template v-slot:item.file_name="{ item }">
                <v-img
                        max-height="200"
                        max-width="200"
                        :src=checkout_site+item.file_path+item.file_name
                ></v-img>
                <p>{{checkout_site}}{{item.file_path}}{{item.file_name}}</p>
            </template>
            <template v-slot:item.priority_value="{ item }">
                <v-chip
                        class="ma-2"
                        color="red"
                        text-color="text-h5"
                        dark
                >
                    {{ item.priority_value }}
                </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-text-field
                        v-model="item.priority_value"
                        label="New Priority Value"
                        hint="越低越優先顯示，10表示是主圖。"
                >
                </v-text-field>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
                loading: true,
                search: '',
                options: {
                    itemsPerPage: 15,
                },
                headers: [
                    {text: 'ID', value: 'id', class: 'text-subtitle-1'},
                    {text: 'Photo', value: 'file_name', class: 'text-subtitle-1'},
                    {text: 'Priority Value', value: 'priority_value', class: 'text-subtitle-1'},
                    {text: 'Actions', value: 'actions', class: 'text-subtitle-1', sortable: false},
                ],
                groups: [],
                group: {},
                dialog: false,
                valid: false,
                firstname: '',
                lastname: '',
                nameRules: [
                    v => !!v || 'Name is required',
                    v => v.length <= 100 || 'Name must be less than 100 characters',
                ],
                email: '',
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+/.test(v) || 'E-mail must be valid',
                ],
                date: new Date().toISOString(),
                menu: false,
                suspend: 1,
                checkout_site: this.$store.state.checkout_site,
            }
        },
        created() {
            // if (Vue.prototype.$islogin){
            this.axios
                .get('/api/group_photo/' + this.$route.params.id + '/search_by_group_id')
                .then(response => {
                    this.loading = false;
                    this.groups = response.data.data;
                });
            // }
            // else{
            //     this.$router.push(Vue.prototype.$loginpage);
            // }
        },
        methods: {
            back() {
                this.$router.go(-1);
            },
            updateGroupPV() {
                this.axios
                    .put("/api/group_photo/" + this.$route.params.id + "/update_priority_value", this.groups)
                    .then((response) => {
                        console.log(response.data.data);
                        this.$router.go(-1);
                    });
            },
        }
    }
</script>

<style>
</style>