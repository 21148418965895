<template>
    <v-card>
        <v-card-title>
            <div class="text-h4 px-4">查詢訂單 (商品編號: {{ this.$route.params.itemcode }})</div>
            <v-btn x-large color="primary" class="mx-4" dark @click.prevent="updateInfo"><div class="text-h5">更新</div></v-btn>
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
            ></v-text-field>
        </v-card-title>
        <v-data-table
                :headers="headers"
                :items="trans"
                :search="search"
                class="elevation-1"
                :loading="loading"
                loading-text="Loading... Please wait"
                show-expand
                :options.sync="options"
                :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-chevron-double-left',
                lastIcon: 'mdi-chevron-double-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus'
            }"
        >
            <template v-slot:item.qty="{ item }">
                <div v-if="item.qty > 1">
                    <v-chip class="ma-2" color="pink" label text-color="white">
                        <v-icon left>
                            mdi-label
                        </v-icon>
                        {{ item.qty }}
                    </v-chip>
                </div>
                <div v-else>
                    {{ item.qty }}
                </div>
            </template>
            <template v-slot:item.transaction_status_list_name="{ item }">
                <div v-if="item.transaction_status_list_name == '待處理'">
                    <v-chip class="ma-2" color="pink" label text-color="white">
                        <v-icon left>
                            mdi-label
                        </v-icon>
                        {{ item.transaction_status_list_name }}
                    </v-chip>
                </div>
                <div v-else>
                    {{ item.transaction_status_list_name }}
                </div>
            </template>
            <template v-slot:item.is_notice="{ item }">
                <v-switch disabled v-model="item.is_notice" color="green darken-3"></v-switch>
            </template>
            <template v-slot:item.is_packup="{ item }">
                <v-switch disabled v-model="item.is_packup" color="green darken-3"></v-switch>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="text-subtitle-1 pa-4">
                    TP ID: {{ item.id}} | Tran ID: {{ item.tran_id}} | Group ID: {{ item.group_id}}<br>
                    Amount: {{ item.currency }} {{ item.amount }}<br>
                    Payment: {{ item.payment_list_name }}<br>
                    Delivery: {{ item.delivery_list_name }}<br>
                    Remarks: {{ item.remarks }}<br>
                    Product Remarks: {{ item.product_remarks }}
                </td>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                        large
                        class="mr-8"
                        @click="editTran(item.tran_id)"
                        color="primary"
                >
                    mdi-file-document-edit-outline
                </v-icon>
                <v-icon
                        large
                        class="mr-8"
                        color="primary"
                        @click.prevent="showOneTran(item.tran_id)"
                >
                    mdi-file-document
                </v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    export default {
        data () {
            return {
                loading: true,
                search: '',
                options: {
                    itemsPerPage: 10,
                },
                headers: [
                    { text: 'Invoice No.', value: 'invoice', class: 'text-subtitle-1' },
                    { text: 'Order Date', value: 'created_at', class: 'text-subtitle-1' },
                    { text: 'Full Item Code', value: 'full_item_code', class: 'text-subtitle-1' },
                    { text: 'Item Name', value: 'item_name', class: 'text-subtitle-1' },
                    { text: 'Att', value: 'att', class: 'text-subtitle-1' },
                    { text: 'Mobile', value: 'mobile', class: 'text-subtitle-1' },
                    { text: 'Unit Price', value: 'price', class: 'text-subtitle-1' },
                    { text: 'Qty', value: 'qty', class: 'text-subtitle-1' },
                    { text: 'Delivery', value: 'delivery_list_name', class: 'text-subtitle-1' },
                    { text: 'Status', value: 'transaction_status_list_name', class: 'text-subtitle-1' },
                    { text: 'Notice', value: 'is_notice', class: 'text-subtitle-1' },
                    { text: 'Packup', value: 'is_packup', class: 'text-subtitle-1' },
                    { text: 'Actions', value: 'actions', class: 'text-subtitle-1', sortable: false },
                ],
                trans: [],
            }
        },
        created() {
            this.axios
                .get('/api/tran/'+this.$route.params.itemcode+'/search_tran_by_itemcode')
                .then(response => {
                    this.loading = false;
                    this.trans = response.data.data;
                });
        },
        methods: {
            editTran(id) {
                this.$router.push('/tran/'+id+'/edit');
                // const routeData = this.$router.resolve('/tran/'+id+'/edit');
                // window.open(routeData.href, '_blank');
            },
            showOneTran(id) {
                this.$router.push('/tran/'+id);
                // const routeData = this.$router.resolve('/tran/'+id);
                // window.open(routeData.href, '_blank');
            },
            updateInfo() {
                this.axios
                    .get('/api/tran/'+this.$route.params.itemcode+'/search_tran_by_itemcode')
                    .then(response => {
                        this.loading = false;
                        this.trans = response.data.data;
                    });
            },
        }
    }
</script>