<template>
    <v-card>
        <v-card-title class="headline grey lighten-2">
            編輯團資料 - Group ID: {{ group.id }}
        </v-card-title>

        <v-card-text>
            <v-form>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field
                                    v-model="group.name"
                                    :counter="255"
                                    label="Name"
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                            <v-text-field
                                    v-model="group.type"
                                    label="Type"
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                            <v-text-field
                                    v-model="group.currency"
                                    label="Currency"
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="2">
                            <div><v-text-field
                                    v-model="group.start_date"
                                    label="Start Date"
                                    hint="例子：2021-01-31 00:00:00"
                                    prepend-icon="mdi-calendar-month"
                            ></v-text-field>
                            </div>
                        </v-col>

                        <v-col cols="12" md="2">
                            <div><v-text-field
                                    v-model="group.expiry_date"
                                    label="Expiry Date"
                                    hint="例子：2021-01-31 00:00:00"
                                    prepend-icon="mdi-calendar-month"
                            ></v-text-field>
                            </div>
                        </v-col>

                        <v-col cols="12" md="1">
                            <v-text-field v-model="group.quota" type="number" label="Quota" prepend-icon="mdi-counter" hint="0代表沒限制" step="10"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="2">
                            <v-text-field
                                    v-model="group.label_text"
                                    :counter="255"
                                    label="Label Text"
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="2">
                            <v-text-field
                                    v-model="group.price_text"
                                    :counter="255"
                                    label="Price Text"
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="2">
                            <v-text-field
                                    v-model="group.delivery_time_text"
                                    :counter="255"
                                    label="Delivery Time Text"
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="1">
                            <v-switch v-model="group.suspend" label="Suspend" color="red darken-3"></v-switch>
                            <!--<div><button class="btn btn-primary btn-lg" @click.prevent="uploadImage">上載圖片</button></div>-->
                        </v-col>
                        <!--<v-col cols="12" md="12">-->
                            <!--<div class="card-body">-->
                                <!--<div class="alert alert-success alert-block" v-if="success === true">-->
                                    <!--<button type="button" class="close" data-dismiss="alert">×</button>-->
                                    <!--<strong>{{ message }}</strong>-->
                                <!--</div>-->

                                <!--<div class="alert alert-danger" v-if="success === false">-->
                                    <!--<strong>上載失敗</strong> <br>出現以下錯誤：<br>-->
                                    <!--<ul>-->
                                        <!--<li v-for="item in message">-->
                                            <!--{{ item }}-->
                                        <!--</li>-->
                                    <!--</ul>-->
                                <!--</div>-->
                            <!--</div>-->
                        <!--</v-col>-->
                        <v-col cols="12" md="12">
                            <v-textarea
                                    solo
                                    name="input-7-4"
                                    label="Description"
                                    v-model="group.description"
                            ></v-textarea>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-textarea
                                    solo
                                    name="input-7-4"
                                    label="Remarks"
                                    v-model="group.remarks"
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">
                            <v-btn x-large color="primary" dark @click.prevent="updateGroup" class="mx-4"><div class="text-h5">儲存更新</div></v-btn>
                            <v-btn x-large color="primary" dark @click.prevent="back" class="mx-4"><div class="text-h5">返回</div></v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
                group: {},
                selected_file: null,
                success: null,
                message: null,
            }
        },
        created() {
            this.axios
                .get("/api/group/"+this.$route.params.id)
                .then((response) => {
                    this.group = response.data.data[0];
                });
        },
        methods: {
            back() {
                this.$router.go(-1);
            },
            onFileSelected(event) {
                this.selected_file = event.target.files[0];
            },
            uploadImage() {
                // if (this.selected_file != null) {
                    const formData = new FormData();
                    formData.set('image', this.selected_file);
                    formData.set('group_id', this.group.id);
                    this.axios
                        .post('/api/group_photo/'+this.group.id+'/update_by_group_id', formData, {headers: {'content-type': 'multipart/form-data'}})
                        .then (response => {
                            this.success = response.data.data.success;
                            this.message = response.data.data.message;
                            console.log(response);
                        });
                // }
            },
            updateGroup() {
                this.group.updated_by = this.$store.state.username;
                this.axios
                    .put("/api/group/"+this.$route.params.id, this.group)
                    .then((response) => {
                        this.$router.push({name: 'GroupNonExpired'});
                        console.log(response.data.data);
                    });
            }
        }
    }
</script>