<template>
    <v-container>
    <v-row>
        <v-col cols="12" md="12">
            <v-card
                    class="mx-auto my-4"
            >
                <v-card-title>
                    <div class="text-h4">Dashboard</div>
                </v-card-title>

                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                            <tr>
                                <td width="50%"><div class="text-h6">有效團</div></td>
                                <td><div class="text-h6">{{ dashboard.d1 }}</div></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">待處理訂單</div></td>
                                <td><div class="text-h6">{{ dashboard.d2 }}</div></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">未跟進訂購申請</div></td>
                                <td><div class="text-h6">{{ dashboard.d3 }}</div></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">本日新增訂單</div></td>
                                <td><div class="text-h6">{{ dashboard.d4 }}</div></td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
            <div class="float-right">Reporting time: {{ (new Date()).toLocaleString('en-GB', { timeZone: 'Asia/Hong_Kong', hour12:false }) }}</div>
        </v-col>
    </v-row>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                dashboard: [],
            }
        },
        created() {
            this.axios
                .get("/api/dashboard/all")
                .then(response => {
                    this.dashboard = response.data.data[0];
                });
        },
        methods: {
            back() {
                this.$router.go(-1);
            },
            updateUser() {
                this.axios
                    .put("/api/group_ask/"+this.$route.params.id, this.group_ask)
                    .then((response) => {
                        this.$router.push({name: 'AllGroupAsk'});
                        console.log(response);
                    });
            }
        }
    }
</script>