<template>
    <v-container>
    <v-row>
        <v-col cols="12" md="12">
            <v-card
                    class="mx-auto my-4"
            >
                <v-card-title>
                    <div class="text-h4 mx-4">編輯客人資料 (customer ID: {{ customer.id }})</div>
                    <v-btn x-large color="primary" class="ma-4" dark @click.prevent="updatecustomer"><div class="text-h5">儲存更新</div></v-btn>
                    <v-btn x-large color="primary" class="ma-4" dark @click.prevent="back"><div class="text-h5">返回</div></v-btn>
                </v-card-title>

                <v-card-text class="mb-n6">
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                            <tr>
                                <td width="20%"><div class="text-h6">Mobile</div></td>
                                <td><div class="text-h6">{{ customer.mobile }}</div></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">Updated By</div></td>
                                <td><div class="text-h6">{{ customer.updated_by }}</div></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">Updated At</div></td>
                                <td><div class="text-h6">{{ customer.updated_at }}</div></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">Name</div></td>
                                <td><div class="text-h6">{{ customer.name }}</div></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">Sex</div></td>
                                <td><div class="text-h6">{{ customer.sex }}</div></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">Email</div></td>
                                <td><div class="text-h6">{{ customer.email }}</div></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">FB Name</div></td>
                                <td><div class="text-h6">{{ customer.fb_full_name }}</div></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">FB UID</div></td>
                                <td><div class="text-h6">{{ customer.fb_uid }}</div></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">customer Type</div></td>
                                <td><div class="text-h6"><v-select item-text="type_name" item-value="id" :items="customer_type" v-model="customer.type_id" outlined class="mt-4"></v-select></div></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">Is Active</div></td>
                                <td><v-switch v-model="customer.is_active" color="green darken-3"></v-switch></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">Remarks (Internal only)</div></td>
                                <td><div class="text-h6 mt-4"><v-textarea
                                        solo
                                        name="input-7-4"
                                        label="Remarks"
                                        v-model="customer.remarks"
                                ></v-textarea></div></td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                customer: [],
                customer_type: [],
            }
        },
        created() {
            this.axios
                .get("/api/customer/"+this.$route.params.id)
                .then(response => {
                    this.customer = response.data.data[0];
                });
            this.axios
                .get('/api/customer_type')
                .then(response => {
                    this.customer_type = response.data.data;
                });
        },
        methods: {
            back() {
                this.$router.go(-1);
            },
            updatecustomer() {
                this.customer.updated_by = this.$store.state.username;
                this.axios
                    .put("/api/customer/"+this.$route.params.id, this.customer)
                    .then((response) => {
                        this.$router.push({name: 'Allcustomer'});
                        console.log(response);
                    });
            }
        }
    }
</script>