<template>
    <v-container fluid>
        <v-row
                align="start"
                justify="center"
                class="py-6"
        >
            <v-col
                    cols="12"
                    sm="8"
                    md="4"
            >
                <v-form ref="entryForm" @submit.prevent="loginHandler">
                    <v-card class="elevation-12">
                        <v-toolbar
                                color="light-green darken-3"
                                dark
                                flat
                        >
                            <v-toolbar-title>Login</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <div class="alert alert-danger alert-block" v-if="success === false">
                                <strong>{{ message }}</strong>
                            </div>
                            <v-row>
                                <v-col
                                        cols="12"
                                        md="12"
                                >
                                    <v-text-field
                                            v-model="user.email"
                                            :rules="emailRules"
                                            label="Email"
                                            required
                                            prepend-icon="mdi-account"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="user.password"
                                            :rules='passwordRules'
                                            label="Password"
                                            required
                                            prepend-icon="mdi-lock"
                                            type="password"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn :disabled="isFormValid" x-large dark color="light-green darken-3" type="submit">Login</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios'
    export default {
        data () {
            return {
                success: null,
                message: null,
                user: {},
                emailRules: [],
                passwordRules: [],
                isFormValid: false,
            }
        },
        methods: {
            loginHandler()
            {
                this.isFormValid = true;
                this.emailRules = [
                    v => !!v || "E-mail is required",
                    v => /.+@.+/.test(v) || "E-mail must be valid"
                ];
                this.passwordRules = [
                    v => !!v || "The input is required"
                ];

                this.$nextTick(function () {
                    if (this.$refs.entryForm.validate()) {
                        this.axios
                            .post('/api/login', this.user)
                            .then(response => {
                                if (response.data.data[0].status == 1){
                                    this.$store.state.islogin = true;
                                    this.$store.state.username = response.data.data[0].user.name;
                                    this.$store.state.user_id = response.data.data[0].user.id;
                                    this.$store.state.token = response.data.data[0].user.accessToken;
                                    axios.defaults.headers.common = {'Accept' : 'application/json', 'Authorization': 'Bearer '+this.$store.state.token};
                                    this.$router.push({name: 'Home'});
                                }
                                else {
                                    this.success = false;
                                    this.message = 'Login failed: Invalid username or password.';
                                }
                            });
                    }
                    else {
                        this.success = false;
                        this.message = 'Login failed: Invalid username or password.';
                    }
                });
                this.isFormValid = false;
            }
        }
    }
</script>