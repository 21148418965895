<template>
  <div class="logout">
    {{ message }}
  </div>
</template>

<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                message: '',
            }
        },
        created() {
            this.axios
                .get('/api/logout')
                .then(response => {
                    this.$store.state.islogin = false;
                    this.$store.state.username = 'guest';
                    this.$store.state.user_id = '';
                    this.$store.state.token = '';
                    axios.defaults.headers.common = {'Accept' : 'application/json', 'Authorization': 'Bearer '+this.$store.state.token};
                    this.message = response.data.data[0].message;
                    this.$router.push({name: 'AdminLogin'});
                });
        },
        methods: {

        },
    }
</script>