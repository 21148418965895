var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('div',{staticClass:"text-h4 px-4"},[_vm._v("團的圖片")]),_c('v-btn',{staticClass:"mx-4 text-h4",attrs:{"color":"primary","dark":"","x-large":""},on:{"click":function($event){$event.preventDefault();return _vm.updateGroupPV($event)}}},[_vm._v("儲存更新")]),_c('v-btn',{staticClass:"mx-4 text-h4",attrs:{"color":"primary","dark":"","x-large":""},on:{"click":function($event){$event.preventDefault();return _vm.back($event)}}},[_vm._v("返回")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.groups,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading... Please wait","options":_vm.options,"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-chevron-double-left',
            lastIcon: 'mdi-chevron-double-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus'
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.file_name",fn:function(ref){
        var item = ref.item;
return [_c('v-img',{attrs:{"max-height":"200","max-width":"200","src":_vm.checkout_site+item.file_path+item.file_name}}),_c('p',[_vm._v(_vm._s(_vm.checkout_site)+_vm._s(item.file_path)+_vm._s(item.file_name))])]}},{key:"item.priority_value",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"text-h5","dark":""}},[_vm._v(" "+_vm._s(item.priority_value)+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"New Priority Value","hint":"越低越優先顯示，10表示是主圖。"},model:{value:(item.priority_value),callback:function ($$v) {_vm.$set(item, "priority_value", $$v)},expression:"item.priority_value"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }