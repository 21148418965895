<template>
    <v-container>
    <v-row>
        <v-col cols="12" md="12">
            <template v-if="error">
                <v-alert
                        border="bottom"
                        color="pink darken-1"
                        dark
                >
                    <div style="white-space:pre-wrap;" v-for="item in error_message" :key="item.message">{{ item[0] }}<br>
                    </div>
                </v-alert>
            </template>
            <v-card
                    class="mx-auto my-4"
            >
                <v-card-title>
                    <div class="text-h4 mx-4">新增客人資料</div>
                </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                            <tr>
                                <td width="20%"><div class="text-h6">Country Calling Codes</div></td>
                                <td><div class="text-h6">
                                    <v-select
                                            :items="country_calling_codes"
                                            item-text="name"
                                            outlined
                                            placeholder="請選擇"
                                            item-value="country_calling_codes"
                                            v-model="selected_country_calling_codes"
                                            class="mt-4"
                                    ></v-select></div>
                                </td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">Mobile</div></td>
                                <td><div class="text-h6">
                                    <v-text-field
                                            outlined
                                            label="電話號碼"
                                            prepend-inner-icon="mdi-cellphone-iphone"
                                            v-model="mobile"
                                            @change="inputMobile"
                                            class="mt-4"
                                    ></v-text-field></div>
                                </td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">customer Type</div></td>
                                <td><div class="text-h6"><v-select item-text="type_name" item-value="id" :items="customer_type" v-model="customer.type_id" outlined class="mt-4"></v-select></div></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">Remarks (Internal only)</div></td>
                                <td><div class="text-h6 mt-4"><v-textarea
                                        solo
                                        name="input-7-4"
                                        label="Remarks"
                                        v-model="customer.remarks"
                                ></v-textarea></div></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><v-btn x-large color="primary" class="ma-4 float-right" dark @click.prevent="addcustomer"><div class="text-h5">新增客人</div></v-btn></td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                customer: {type_id:1, is_active: false},
                customer_type: [],
                country_calling_codes: ['+853','+852','+86'],
                selected_country_calling_codes: '+853',
                mobile: null,
                error: false,
                error_message: '',
            }
        },
        created() {
            this.axios
                .get('/api/customer_type')
                .then(response => {
                    this.customer_type = response.data.data;
                });
        },
        methods: {
            addcustomer() {
                this.customer.mobile = this.selected_country_calling_codes+this.mobile;
                this.customer.updated_by = this.$store.state.username;
                this.axios
                    .post("/api/customer", this.customer)
                    .then((response) => {
                        console.log(response.data.data);
                        this.$router.push({name: 'Allcustomer'});
                    })
                    .catch((error) => {
                        if (error.response) {
                            this.error_message = error.response.data.errors;
                            this.error = true;
                            console.log(error.response);
                        }
                    });
            },
            inputMobile(){
                this.mobile = this.mobile.replace(/[^0-9#]/g,'');
            },
        }
    }
</script>
