<template>
    <v-card>
        <v-card-title>
            <div class="text-h4 px-4">黑名單客人</div>
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
            ></v-text-field>
        </v-card-title>
        <v-data-table
                :headers="headers"
                :items="customers"
                :search="search"
                class="elevation-1"
                :loading="loading"
                loading-text="Loading... Please wait"
                :options.sync="options"
                :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-chevron-double-left',
                lastIcon: 'mdi-chevron-double-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus'
            }"
        >
            <template v-slot:item.is_active="{ item }">
                <v-switch disabled v-model="item.is_active" color="green darken-3"></v-switch>
            </template>
            <template v-slot:item.has_password="{ item }">
                <v-chip class="ma-4" color="green" text-color="white" v-if="item.has_password === '1'">
                    <div class="text-h5">有設定</div>
                </v-chip>
                <v-chip class="ma-4" color="red" text-color="white" v-else>
                    <div class="text-h5">未設定</div>
                </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                        large
                        class="mr-8"
                        @click="editcustomer(item.id)"
                        color="primary"
                >
                    mdi-file-document-edit-outline
                </v-icon>
                <v-icon
                        large
                        class="mr-8"
                        color="primary"
                        @click.prevent="showTran(item.mobile)"
                >
                    mdi-currency-usd
                </v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
                loading: true,
                search: '',
                options: {
                    itemsPerPage: 10,
                },
                headers: [
                    { text: 'User ID', value: 'id', class: 'text-subtitle-1' },
                    { text: 'Mobile', value: 'mobile', class: 'text-subtitle-1' },
                    { text: 'Password', value: 'has_password', class: 'text-subtitle-1' },
                    { text: 'SMS Code', value: 'smscode', class: 'text-subtitle-1' },
                    { text: 'Name', value: 'name', class: 'text-subtitle-1' },
                    { text: 'Sex', value: 'sex', class: 'text-subtitle-1' },
                    { text: 'Email', value: 'email', class: 'text-subtitle-1' },
                    { text: 'FB Name', value: 'fb_full_name', class: 'text-subtitle-1' },
                    { text: 'FB UID', value: 'fb_uid', class: 'text-subtitle-1' },
                    { text: 'Remarks', value: 'remarks', class: 'text-subtitle-1' },
                    { text: 'Active', value: 'is_active', class: 'text-subtitle-1' },
                    { text: 'Discount', value: 'discount', class: 'text-subtitle-1' },
                    { text: 'Type', value: 'type_name', class: 'text-subtitle-1' },
                    { text: 'Actions', value: 'actions', class: 'text-subtitle-1', sortable: false },
                ],
                customers: [],
            }
        },
        created() {
            this.axios
                .get('/api/blacklist_customer')
                .then(response => {
                    this.loading = false;
                    this.customers = response.data.data;
                });
        },
        methods: {
            editcustomer(id) {
                this.$router.push('/customer/'+id+'/edit');
            },
            showTran(mobile) {
                this.$router.push('/tran/'+mobile+'/search_tran_by_mobile/');
            }
        }
    }
</script>