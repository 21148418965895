<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="6">
                <v-carousel class="mx-auto my-4" height="400">
                    <v-carousel-item v-for="item in photos" :key=item.id>
                        <v-img :src="checkout_site+item.file_path+item.file_name" contain max-height="400"></v-img>
                    </v-carousel-item>
                </v-carousel>
            </v-col>
            <v-col cols="12" md="6">
                <v-card class="mx-auto my-4">
                    <v-card-title>
                        <v-chip class="ma-2" color="red" label text-color="white" large v-show="group.label_text != null">
                            <div class="text-h6">{{ group.label_text }}</div>
                        </v-chip>
                        <div class="text-h6 mx-4">{{ group.name }}</div>
                    </v-card-title>
                    <v-card-text class="mb-auto">
                        <v-simple-table>
                            <template v-slot:default>
                                <tbody>
                                <tr>
                                    <td width="20%">
                                        <div class="body-1">截單日期</div>
                                    </td>
                                    <td>
                                        <div class="body-1">{{ group.expiry_date }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="body-1">預計貨期</div>
                                    </td>
                                    <td>
                                        <div class="body-1">{{ group.delivery_time_text }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="body-1">售價</div>
                                    </td>
                                    <td>
                                        <div v-if="group.price_text !== null" class="body-1"><del class="red--text">{{ group.currency }} {{ group.price_text }}</del> {{ price_text }}</div>
                                        <div v-else class="body-1">{{ price_text }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td valign="top" class="py-4">
                                        <div class="body-1">商品介紹</div>
                                    </td>
                                    <td valign="top" class="py-4">
                                        <div class="body-1" v-html="group.description"></div>
                                    </td>
                                </tr>
                                <tr v-show="group.remarks != null">
                                    <td valign="top" class="py-4">
                                        <div class="body-1">備註</div>
                                    </td>
                                    <td valign="top" class="py-4">
                                        <div class="body-1" v-html="group.remarks"></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td valign="top" class="py-4">
                                        <div class="body-1">付款方式</div>
                                    </td>
                                    <td valign="top" class="py-4">
                                        <div class="body-1">貨到付款 (澳門客人)</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td valign="top" class="py-4">
                                        <div class="body-1">配送方式</div>
                                    </td>
                                    <td valign="top" class="py-4">
                                        <div class="body-1">門市自取 (荷蘭園店)</div>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="6">
                <v-card class="mx-auto my-0">
                    <v-col cols="12" md="12">
                        <v-chip class="mt-2 mx-2" color="green" label large text-color="white">
                            <div class="text-h4">商品選項</div>
                        </v-chip>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-select
                                :items="products"
                                item-text="att"
                                outlined
                                placeholder="請選擇"
                                v-model="selection"
                                item-value="id"
                                @change="addProductChange(selection)"
                                @click="addProductClick(selection)"
                                class="px-2"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-chip class="mt-2 mx-2" color="green" label large text-color="white">
                            <div class="text-h4">聯絡資料</div>
                        </v-chip>
                    </v-col>
                    <v-row class="mt-2 mx-2">
                        <v-col cols="12" md="4">
                            <v-select
                                    :items="country_calling_codes"
                                    item-text="name"
                                    outlined
                                    placeholder="請選擇"
                                    item-value="country_calling_codes"
                                    v-model="selected_country_calling_codes"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-text-field
                                    outlined
                                    label="電話號碼"
                                    prepend-inner-icon="mdi-cellphone-iphone"
                                    v-model="mobile"
                                    @change="inputMobile"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card color="blue-grey lighten-4">
                    <v-chip class="ma-2" color="blue-grey darken-4" label large text-color="white">
                        <div class="text-h4">我的訂單</div>
                    </v-chip>
                    <v-alert
                            v-model="alert_1"
                            border="bottom"
                            color="red lighten-1"
                            dark
                            class="ma-2 text-h3"
                    >
                        <div v-html="alert_message" ref="alert_here">{{ alert_message }}</div>
                    </v-alert>
                    <v-card class="mx-2 mt-1 pa-2 blue-grey lighten-5" outlined
                            v-for="item in selected_product" :key="item.id">
                        <div class="text-h4 text-left"> {{ item.item_name}} - {{ item.att }} (${{ item.price }}) <br>
                            <v-btn class="ma-2" text icon color="light-blue darken-2"
                                   @click.prevent="minusCount(item.id)">
                                <v-icon x-large>mdi-minus-box</v-icon>
                            </v-btn>
                            數量: {{ item.count }}
                            <v-btn class="ma-2" text icon color="light-blue darken-2"
                                   @click.prevent="addCount(item.id)">
                                <v-icon x-large>mdi-plus-box</v-icon>
                            </v-btn>
                        </div>
                    </v-card>
                    <v-card class="pa-4 mb-2 mx-2 mt-2 blue-grey lighten-3 black--text" outlined>
                        <div class="text-h4 text-left">
                            金額({{ group.currency }}): ${{ total_selected_price }}
                        </div>

                    </v-card>
                    <!--<v-card class="pa-4 mb-2 mx-2 mt-2 blue-grey lighten-3 black&#45;&#45;text" outlined>-->
                        <!--<div class="text-h4 text-left">-->
                            <!--付款方式: 貨到付款 (澳門客人)-->
                        <!--</div>-->
                    <!--</v-card>-->
                    <!--<v-card class="pa-4 mb-2 mx-2 mt-2 blue-grey lighten-3 black&#45;&#45;text" outlined>-->
                        <!--<div class="text-h4 text-left">-->
                            <!--配送方式: 門市自取 (荷蘭園店)-->
                        <!--</div>-->
                    <!--</v-card>-->
                    <v-col cols="12" md="12" class="text-right">
                        <v-btn x-large class="ma-2" color="light-blue darken-2" dark large @click.prevent="PlaceAnOrder">
                            <div class="text-h4">確定下單</div>
                        </v-btn>
                    </v-col>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="12">
                <v-card class="mx-auto mt-2 pa-10" outlined color="blue-grey lighten-5">
                    <v-chip class="mb-4" color="blue-grey darken-4" label large text-color="white">
                        <div class="text-h4">商品相片</div>
                    </v-chip>
                    <div v-for="item in photos" :key=item.id>
                        <v-img class="white--text align-end" max-width="1400" :src="checkout_site+item.file_path+item.file_name"></v-img>
                    </div>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                group: [],
                products: [],
                payments: [],
                deliverys: [],
                price_list: [],
                price_text: '',
                selection: null,
                selected_product: [],
                //selected_delivery: [],
                //selected_payment: [],
                total_selected_price: 0,
                alert_1: false,
                alert_message: '',
                mobile: null,
                // country_calling_codes: ['+853','+852','+86'],
                country_calling_codes: ['+853'],
                selected_country_calling_codes: '+853',
                selected_delivery_address: null,
                tran_info: { payment_list_id: 1 , delivery_list_id: 1},
                user_id: '-1',
                user_delivery_address: {},
                checkout_site: this.$store.state.checkout_site,
                photos: {},
            }
        },
        created() {
            // this.axios
            //     .get("/api/group/"+this.$route.params.id+"/check_group")
            //     .then((response) => {
            //         if(response.data.data.link !== "show"){
            //             this.$router.push({path: '/group/'+this.$route.params.id+"/"+response.data.data.link});
            //         }
            //     });
            this.axios
                .get("/api/group/"+this.$route.params.id)
                .then((response) => {
                    this.group = response.data.data[0];
                });
            this.axios
                .get("/api/group_product/"+this.$route.params.id+"/search_by_group_id")
                .then((response) => {
                    this.products = response.data.data;
                });
            this.axios
                .get("/api/group_payment/"+this.$route.params.id+"/search_by_group_id")
                .then((response) => {
                    this.payments = response.data.data;
                });
            this.axios
                .get("/api/group_delivery/"+this.$route.params.id+"/search_by_group_id")
                .then((response) => {
                    this.deliverys = response.data.data;
                });
            this.axios
                .get("/api/group_photo/"+this.$route.params.id+"/search_by_group_id")
                .then((response) => {
                    this.photos = response.data.data;
                });
        },
        beforeUpdate() {
            for (var x = 0; x < this.products.length; x++) {
                this.price_list.push(this.products[x].price);
            }
            if (Math.min(...this.price_list) === Math.max(...this.price_list)) {
                this.price_text = this.group.currency + " " + Math.min(...this.price_list);
            }
            else {
                this.price_text = this.group.currency + " " + Math.min(...this.price_list) + " ~ " + this.group.currency + " " + Math.max(...this.price_list);
            }
        },
        mounted() {
        },
        methods: {
            inputMobile(){
                this.mobile = this.mobile.replace(/[^0-9#]/g,'');
            },
            addProductChange(id) {
                if (id != null) {
                    let item = this.selected_product.find((item) => item.id == id);
                    if (typeof item !== 'undefined') {
                        let myindex = this.selected_product.findIndex((item) => item.id == id);
                        item.count += 1;
                        this.selected_product.splice(myindex, 1, item);
                    }
                    else {
                        let myitem = this.products.find((item) => item.id == id);
                        myitem.count += 1;
                        this.selected_product.push(myitem);
                    }
                    this.total_selected_price = 0;
                    for (var x = 0; x < this.selected_product.length; x++) {
                        this.total_selected_price += this.selected_product[x].price * this.selected_product[x].count;
                    }
                    this.alert_1 = false;
                }
            },
            addProductClick(id) {
                if (id != null && this.selected_product.length === 0) {
                    let item = this.selected_product.find((item) => item.id == id);
                    if (typeof item !== 'undefined') {
                        let myindex = this.selected_product.findIndex((item) => item.id == id);
                        item.count += 1;
                        this.selected_product.splice(myindex, 1, item);
                    }
                    else {
                        let myitem = this.products.find((item) => item.id == id);
                        myitem.count += 1;
                        this.selected_product.push(myitem);
                    }
                    this.total_selected_price = 0;
                    for (var x = 0; x < this.selected_product.length; x++) {
                        this.total_selected_price += this.selected_product[x].price * this.selected_product[x].count;
                    }
                    this.alert_1 = false;
                }
            },
            addCount(id) {
                let item = this.selected_product.find((item) => item.id == id);
                if (id != null) {
                    let myindex = this.selected_product.findIndex((item) => item.id == id);
                    item.count += 1;
                    this.selected_product.splice(myindex, 1, item);
                }
                this.total_selected_price = 0;
                for (var x = 0; x < this.selected_product.length; x++) {
                    this.total_selected_price += this.selected_product[x].price * this.selected_product[x].count;
                }
            },
            minusCount(id) {
                let item = this.selected_product.find((item) => item.id == id);
                if (id != null) {
                    let myindex = this.selected_product.findIndex((item) => item.id == id);
                    item.count -= 1;
                    if (item.count === 0) {
                        this.selected_product.splice(myindex, 1);
                        this.selection = null;
                    }
                    else {
                        this.selected_product.splice(myindex, 1, item);
                    }
                }
                this.total_selected_price = 0;
                for (var x = 0; x < this.selected_product.length; x++) {
                    this.total_selected_price += this.selected_product[x].price * this.selected_product[x].count;
                }
            },
            PlaceAnOrder() {
                this.alert_message = '';
                this.alert_1 = false;
                // this.alert_2 = false;
                if (Array.isArray(this.selected_product) && this.selected_product.length === 0) {
                    this.alert_1 = true;
                    this.alert_message += "請選擇商品加入我的訂單!<br>";
                }
                else if (this.mobile === null || this.mobile === "") {
                    this.alert_1 = true;
                    this.alert_message += "請輸入聯絡資料!<br>";
                }
                else if (this.selected_country_calling_codes === '+853' && this.mobile.length != 8) {
                    this.alert_1 = true;
                    this.alert_message += "電話號碼不正確!<br>";
                }
                else {
                    this.tran_info.sex = this.$route.query.s;
                    this.tran_info.fb_full_name = this.$route.query.fn;
                    this.tran_info.fb_uid = this.$route.query.ref;
                    this.tran_info.source = this.$route.query.d;
                    this.tran_info.group_id = this.group.id;
                    this.tran_info.user_id = this.user_id;
                    this.tran_info.mobile = this.selected_country_calling_codes + this.mobile;
                    this.tran_info.amount = this.total_selected_price;
                    this.tran_info.currency = this.group.currency;
                    this.axios
                        .post('/api/tran', this.tran_info)
                        .then(response => {
                            for (let i = 0; i < this.selected_product.length; i++) {
                                this.selected_product[i].transaction_id = response.data.data.tran_id;
                                this.axios
                                    .post('/api/tran_product', this.selected_product[i]);
                            }
                            if (this.selected_delivery_address !== null) {
                                this.user_delivery_address.user_id = this.user_id;
                                this.user_delivery_address.transaction_id = response.data.data.tran_id;
                                this.user_delivery_address.address = this.selected_delivery_address;
                                this.axios
                                    .post('/api/user_delivery_address', this.user_delivery_address);
                            }
                            this.$router.push({path: '/'});
                        });

                }
            },
        },
    }
</script>

<style scoped>
    /*.disabled {*/
    /*color: white;*/
    /*cursor: not-allowed;*/
    /*}*/
</style>