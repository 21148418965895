<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12">
                <v-card class="mx-auto my-4">
                    <v-card-title>
                        <div class="text-h4 mx-4">查詢訂單 (Remarks)</div>
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table>
                            <template v-slot:default>
                                <tbody>
                                <tr>
                                    <td width="20%"><div class="text-h6">Remarks</div></td>
                                    <td><div class="text-h6">
                                        <v-text-field
                                                outlined
                                                label="Remarks"
                                                prepend-inner-icon="mdi-clipboard-text-search-outline"
                                                v-model="remarks"
                                                class="mt-4"
                                        ></v-text-field></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><v-btn x-large color="primary" class="ma-4 float-right" dark @click.prevent="SearchTran"><div class="text-h5">查詢訂單</div></v-btn></td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                remarks: null,
            }
        },
        created() {
        },
        methods: {
            SearchTran() {
                this.$router.push('/tran/'+this.remarks+'/search_tran_by_remarks/');
            },
        }
    }
</script>