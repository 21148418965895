var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('div',{staticClass:"text-h4 px-4"},[_vm._v("查詢訂單 (Remarks: "+_vm._s(this.$route.params.remarks)+")")]),_c('v-btn',{staticClass:"mx-4",attrs:{"x-large":"","color":"primary","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.updateInfo($event)}}},[_c('div',{staticClass:"text-h5"},[_vm._v("更新")])]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.trans,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading... Please wait","show-expand":"","options":_vm.options,"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-chevron-double-left',
            lastIcon: 'mdi-chevron-double-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus'
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.is_notice",fn:function(ref){
        var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":"","color":"green darken-3"},model:{value:(item.is_notice),callback:function ($$v) {_vm.$set(item, "is_notice", $$v)},expression:"item.is_notice"}})]}},{key:"item.is_packup",fn:function(ref){
        var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":"","color":"green darken-3"},model:{value:(item.is_packup),callback:function ($$v) {_vm.$set(item, "is_packup", $$v)},expression:"item.is_packup"}})]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{staticClass:"text-subtitle-1 pa-4",attrs:{"colspan":headers.length}},[_vm._v(" TP ID: "+_vm._s(item.id)+" | Tran ID: "+_vm._s(item.tran_id)+" | Group ID: "+_vm._s(item.group_id)),_c('br'),_vm._v(" Amount: "+_vm._s(item.currency)+" "+_vm._s(item.amount)),_c('br'),_vm._v(" Payment: "+_vm._s(item.payment_list_name)),_c('br'),_vm._v(" Delivery: "+_vm._s(item.delivery_list_name)),_c('br'),_vm._v(" Remarks: "+_vm._s(item.remarks)),_c('br'),_vm._v(" Product Remarks: "+_vm._s(item.product_remarks)+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-8",attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.editTran(item.tran_id)}}},[_vm._v(" mdi-file-document-edit-outline ")]),_c('v-icon',{staticClass:"mr-8",attrs:{"large":"","color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.showOneTran(item.tran_id)}}},[_vm._v(" mdi-file-document ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }