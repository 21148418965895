import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import AdminLogin from '../views/AdminLogin.vue'
import AdminLogout from '../views/AdminLogout.vue'
import AddAdmin from '../views/AddAdmin.vue'
import Dashboard from '../views/Dashboard'
import Dashboard_order from '../views/Dashboard_order'
import AllGroupAsk from '../views/AllGroupAsk'
import GroupAskNonClosed from '../views/GroupAskNonClosed'
import EditGroupAsk from '../views/EditGroupAsk'
import AddGroup from '../views/AddGroup.vue'
import GroupNonExpired from '../views/GroupNonExpired'
import AllGroup from '../views/AllGroup'
import EditGroup from '../views/EditGroup'
import ShowOneGroup from '../views/ShowOneGroup'
import EditGroupPhoto from '../views/EditGroupPhoto'
import SearchTransactionBy3Month from '../views/SearchTransactionBy3Month'
import AllTransaction from '../views/AllTransaction'
import SearchTransactionByGroupID from '../views/SearchTransactionByGroupID'
import ShowOneTran from '../views/ShowOneTran'
import EditTran from '../views/EditTran'
import SearchTransactionByStatus from '../views/SearchTransactionByStatus'
import Allcustomer from '../views/AllCustomer'
import Editcustomer from '../views/EditCustomer'
import SearchTransactionByMobile from '../views/SearchTransactionByMobile'
import SearchTransactionByMobile1 from '../views/SearchTransactionByMobile1'
import SearchTransactionByItemCode from '../views/SearchTransactionByItemCode'
import SearchTransactionByItemName from '../views/SearchTransactionByItemName'
import SearchTransactionByStatus1 from '../views/SearchTransactionByStatus1'
import SearchTransactionByInvoice from '../views/SearchTransactionByInvoice'
import SearchTransactionByRemarks from '../views/SearchTransactionByRemarks'
import SearchTransactionByProductRemarks from '../views/SearchTransactionByProductRemarks'
import SearchCustomerByMobile from '../views/SearchCustomerByMobile'
import ShowVipcustomer from '../views/ShowVipCustomer'
import ShowBlacklistcustomer from '../views/ShowBlacklistCustomer'
import Addcustomer from '../views/AddCustomer'
import InputSearchTranByMobile from '../views/InputSearchTranByMobile'
import InputSearchTranByItemCode from '../views/InputSearchTranByItemCode'
import InputSearchTranByItemName from '../views/InputSearchTranByItemName'
import InputSearchTranByStatus from '../views/InputSearchTranByStatus'
import InputSearchTranByInvoice from '../views/InputSearchTranByInvoice'
import InputSearchTranByRemarks from '../views/InputSearchTranByRemarks'
import InputSearchTranByProductRemarks from '../views/InputSearchTranByProductRemarks'
import ShowOpenGroup from '../views/ShowOpenGroup'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Dashboard
    },
    {
        path: '/login',
        name: 'AdminLogin',
        component: AdminLogin
    },
    {
        path: '/logout',
        name: 'AdminLogout',
        component: AdminLogout
    },
    {
        path: '/admin/add',
        name: 'AddAdmin',
        component: AddAdmin
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/dashboard_order',
        name: 'Dashboard_order',
        component: Dashboard_order
    },
    {
        path: '/group_ask/all',
        name: 'AllGroupAsk',
        component: AllGroupAsk
    },
    {
        path: '/group_ask/not_yet_closed',
        name: 'GroupAskNonClosed',
        component: GroupAskNonClosed
    },
    {
        path: '/group_ask/:id/edit',
        name: 'EditGroupAsk',
        component: EditGroupAsk
    },
    {
        path: '/group/add',
        name: 'AddGroup',
        component: AddGroup
    },
    {
        path: '/group/non_expired',
        name: 'GroupNonExpired',
        component: GroupNonExpired
    },
    {
        path: '/group/all',
        name: 'AllGroup',
        component: AllGroup
    },
    {
        path: '/group/:id/edit',
        name: 'EditGroup',
        component: EditGroup
    },
    {
        path: '/group/:id',
        name: 'ShowOneGroup',
        component: ShowOneGroup
    },
    {
        path: '/group_photo/:id/edit',
        name: 'EditGroupPhoto',
        component: EditGroupPhoto
    },
    {
        path: '/tran/search_tran_by_3_month',
        name: 'SearchTransactionBy3Month',
        component: SearchTransactionBy3Month
    },
    {
        path: '/tran/alltran',
        name: 'AllTransaction',
        component: AllTransaction
    },
    {
        path: '/tran/:id/search_tran_by_group_id',
        name: 'SearchTransactionByGroupID',
        component: SearchTransactionByGroupID
    },
    {
        path: '/tran/search_tran_by_status1',
        name: 'SearchTransactionByStatus1',
        component: SearchTransactionByStatus1
    },
    {
        path: '/tran/:invoice/search_tran_by_invoice',
        name: 'SearchTransactionByInvoice',
        component: SearchTransactionByInvoice
    },
    {
        path: '/tran/:remarks/search_tran_by_remarks',
        name: 'SearchTransactionByRemarks',
        component: SearchTransactionByRemarks
    },
    {
        path: '/tran/:remarks/search_tran_by_product_remarks',
        name: 'SearchTransactionByProductRemarks',
        component: SearchTransactionByProductRemarks
    },
    {
        path: '/tran/:id',
        name: 'ShowOneTran',
        component: ShowOneTran
    },
    {
        path: '/tran/:id/edit',
        name: 'EditTran',
        component: EditTran
    },
    {
        path: '/tran/:id/search_tran_by_status',
        name: 'SearchTransactionByStatus',
        component: SearchTransactionByStatus
    },
    {
        path: '/customer/all',
        name: 'Allcustomer',
        component: Allcustomer
    },
    {
        path: '/customer/:id/edit',
        name: 'Editcustomer',
        component: Editcustomer
    },
    {
        path: '/tran/:mobile/search_tran_by_mobile',
        name: 'SearchTransactionByMobile',
        component: SearchTransactionByMobile
    },
    {
        path: '/tran/:mobile/search_tran_by_mobile1',
        name: 'SearchTransactionByMobile1',
        component: SearchTransactionByMobile1
    },
    {
        path: '/tran/:itemcode/search_tran_by_itemcode',
        name: 'SearchTransactionByItemCode',
        component: SearchTransactionByItemCode
    },
    {
        path: '/tran/:itemname/search_tran_by_itemname',
        name: 'SearchTransactionByItemName',
        component: SearchTransactionByItemName
    },
    {
        path: '/customer/search_customer_by_mobile',
        name: 'SearchCustomerByMobile',
        component: SearchCustomerByMobile
    },
    {
        path: '/customer/vipcustomer',
        name: 'ShowVipcustomer',
        component: ShowVipcustomer
    },
    {
        path: '/customer/blacklistcustomer',
        name: 'ShowBlacklistcustomer',
        component: ShowBlacklistcustomer
    },
    {
        path: '/customer/addcustomer',
        name: 'Addcustomer',
        component: Addcustomer
    },
    {
        path: '/tran/input/input_search_tran_by_mobile',
        name: 'InputSearchTranByMobile',
        component: InputSearchTranByMobile
    },
    {
        path: '/tran/input/input_search_tran_by_itemcode',
        name: 'InputSearchTranByItemCode',
        component: InputSearchTranByItemCode
    },
    {
        path: '/tran/input/input_search_tran_by_itemname',
        name: 'InputSearchTranByItemName',
        component: InputSearchTranByItemName
    },
    {
        path: '/tran/input/input_search_tran_by_status',
        name: 'InputSearchTranByStatus',
        component: InputSearchTranByStatus
    },
    {
        path: '/tran/input/input_search_tran_by_invoice',
        name: 'InputSearchTranByInvoice',
        component: InputSearchTranByInvoice
    },
    {
        path: '/tran/input/input_search_tran_by_remarks',
        name: 'InputSearchTranByRemarks',
        component: InputSearchTranByRemarks
    },
    {
        path: '/tran/input/input_search_tran_by_product_remarks',
        name: 'InputSearchTranByProductRemarks',
        component: InputSearchTranByProductRemarks
    },
    {
        name: 'show_open_group',
        path: '/buy/:id/show',
        component: ShowOpenGroup
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name !== 'AdminLogin' && !store.state.islogin) next({ name: 'AdminLogin' })
    else next()
})

export default router
