<template>
    <v-card>
        <template v-if="error">
            <v-alert
                    border="bottom"
                    color="pink darken-1"
                    dark
            >
                <div style="white-space:pre-wrap;" v-for="item in error_message" :key="item.name">{{ item[0] }}<br>
                </div>
            </v-alert>
        </template>
        <v-tabs>
            <v-tab>
                <v-icon left large>mdi-application-import</v-icon>
                <div class="text-h5">開團管理</div>
            </v-tab>
            <v-tab>
                <v-icon left large>mdi-barcode-scan</v-icon>
                <div class="text-h5">商品資料</div>
            </v-tab>
            <v-tab>
                <v-icon left large>mdi-credit-card-multiple</v-icon>
                <div class="text-h5">支付配送</div>
            </v-tab>

            <v-tab-item>
                <v-card>
                    <v-card-text>
                        <v-form>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="2">
                                        <v-text-field
                                                v-model="group.label_text"
                                                :counter="255"
                                                label="Label Text"
                                                required
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="4">
                                        <v-text-field
                                                v-model="group.name"
                                                :counter="255"
                                                label="Name"
                                                required
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="3">
                                        <v-select
                                                v-model="group.type"
                                                :items="type_text"
                                                label="Type"
                                                required
                                                outlined
                                        ></v-select>
                                    </v-col>

                                    <v-col cols="12" md="3">
                                        <v-select
                                                v-model="group.currency"
                                                :items="currency_text"
                                                label="Currency"
                                                required
                                                outlined
                                        ></v-select>
                                    </v-col>

                                    <v-col cols="12" md="2">
                                        <div>
                                            <v-text-field
                                                    v-model="group.start_date"
                                                    label="Start Date"
                                                    hint="例子：2021-01-31 23:59:59"
                                                    prepend-icon="mdi-calendar-month">
                                            </v-text-field>
                                        </div>
                                    </v-col>

                                    <v-col cols="12" md="2">
                                        <div>
                                            <v-text-field
                                                    v-model="group.expiry_date"
                                                    label="Expiry Date"
                                                    hint="例子：2021-01-31 23:59:59"
                                                    prepend-icon="mdi-calendar-month">
                                            </v-text-field>
                                        </div>
                                    </v-col>

                                    <v-col cols="12" md="2">
                                        <!--<v-text-field v-model="group.quota" type="number" label="Quota"-->
                                                      <!--prepend-icon="mdi-counter" hint="0：代表沒限制" step="10"></v-text-field>-->
                                        上載圖片
                                        <div class="form-group">
                                            <input type="file" @change="onFileSelected" accept="image/*" multiple>
                                        </div>
                                    </v-col>

                                    <v-col cols="12" md="2">
                                        <v-text-field
                                                v-model="group.price_text"
                                                :counter="255"
                                                label="Price Text"
                                                required
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="2">
                                        <v-text-field
                                                v-model="group.delivery_time_text"
                                                :counter="255"
                                                label="Delivery Time Text"
                                                required
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="2">
                                        <v-switch v-model="group.suspend" label="Suspend"
                                                  color="red darken-3"></v-switch>
                                    </v-col>

                                    <v-col cols="12" md="12">
                                        <v-textarea
                                                solo
                                                label="Description"
                                                v-model="group.description"
                                        ></v-textarea>
                                    </v-col>

                                    <v-col cols="12" md="12">
                                        <v-textarea
                                                solo
                                                label="Remarks"
                                                v-model="group.remarks"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                            label="Item Code"
                                            weith="100px"
                                            v-model="find_item_code"
                                    >
                                        <v-icon slot="append" large color="primary"
                                                @click.prevent="findItem(find_item_code)">mdi-cloud-download
                                        </v-icon>
                                    </v-text-field>
                                    <v-btn x-large color="primary" dark @click.prevent="addItem">
                                        <div class="text-h5">新增沒有商品</div>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-data-table
                                            v-model="selected_items"
                                            :headers="item_headers"
                                            :items="items"
                                            item-key="full_item_code"
                                            show-select
                                            class="elevation-1"
                                    >

                                        <template v-slot:item.full_item_code="props">
                                            <v-edit-dialog
                                                    :return-value.sync="props.item.full_item_code"
                                                    large
                                                    persistent
                                                    @save="save"
                                                    @cancel="cancel"
                                                    @open="open"
                                                    @close="close"
                                            > {{ props.item.full_item_code }}
                                                <template v-slot:input>
                                                    <v-text-field
                                                            v-model="props.item.full_item_code"
                                                            label="Edit"
                                                            single-line
                                                            counter
                                                    ></v-text-field>
                                                </template>
                                            </v-edit-dialog>
                                        </template>

                                        <template v-slot:item.item_name="props">
                                            <v-edit-dialog
                                                    :return-value.sync="props.item.item_name"
                                                    large
                                                    persistent
                                                    @save="save"
                                                    @cancel="cancel"
                                                    @open="open"
                                                    @close="close"
                                            > {{ props.item.item_name }}
                                                <template v-slot:input>
                                                    <v-text-field
                                                            v-model="props.item.item_name"
                                                            label="Edit"
                                                            single-line
                                                            counter
                                                    ></v-text-field>
                                                </template>
                                            </v-edit-dialog>
                                        </template>

                                        <template v-slot:item.att="props">
                                            <v-edit-dialog
                                                    :return-value.sync="props.item.att"
                                                    large
                                                    persistent
                                                    @save="save"
                                                    @cancel="cancel"
                                                    @open="open"
                                                    @close="close"
                                            > {{ props.item.att }}
                                                <template v-slot:input>
                                                    <v-text-field
                                                            v-model="props.item.att"
                                                            label="Edit"
                                                            single-line
                                                            counter
                                                    ></v-text-field>
                                                </template>
                                            </v-edit-dialog>
                                        </template>

                                        <template v-slot:item.price="props">
                                            <v-edit-dialog
                                                    :return-value.sync="props.item.price"
                                                    large
                                                    persistent
                                                    @save="save"
                                                    @cancel="cancel"
                                                    @open="open"
                                                    @close="close"
                                            > {{ props.item.price }}
                                                <template v-slot:input>
                                                    <v-text-field
                                                            v-model="props.item.price"
                                                            label="Edit"
                                                            single-line
                                                            counter
                                                    ></v-text-field>
                                                </template>
                                            </v-edit-dialog>
                                        </template>


                                        <template v-slot:item.qty="props">
                                            <v-edit-dialog
                                                    :return-value.sync="props.item.qty"
                                                    large
                                                    persistent
                                                    @save="save"
                                                    @cancel="cancel"
                                                    @open="open"
                                                    @close="close"
                                            > {{ props.item.qty }}
                                                <template v-slot:input>
                                                    <v-text-field
                                                            v-model="props.item.qty"
                                                            label="Edit"
                                                            single-line
                                                            counter
                                                    ></v-text-field>
                                                </template>
                                            </v-edit-dialog>
                                        </template>

                                    </v-data-table>
                                    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                                        {{ snackText }}

                                        <template v-slot:action="{ attrs }">
                                            <v-btn v-bind="attrs" text @click="snack = false">Close</v-btn>
                                        </template>
                                    </v-snackbar>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-card>
                                        <v-chip class="ma-4" color="green" text-color="white">
                                            <div class="text-h5">已加入本團的商品</div>
                                            <v-avatar right class="green darken-4">
                                                {{ selected_items.length }}
                                            </v-avatar>
                                        </v-chip>
                                        <div class="pa-4">
                                            <ul>
                                                <li class="text-h5" v-for="item in selected_items"
                                                    :key="item.full_item_code"> {{ item.full_item_code }} | {{
                                                    item.item_name}} | {{ item.att }} | {{ item.price }} | {{ item.qty
                                                    }}
                                                </li>
                                            </ul>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-data-table
                                            v-model="selected_payment"
                                            :headers="payment_headers"
                                            :items="payments"
                                            item-key="id"
                                            show-select
                                            class="elevation-1"
                                    >
                                    </v-data-table>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-data-table
                                            v-model="selected_delivery"
                                            :headers="delivery_headers"
                                            :items="deliverys"
                                            item-key="id"
                                            show-select
                                            class="elevation-1"
                                    >
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-spacer></v-spacer>
                                <v-col cols="12" md="2">
                                    <v-btn x-large color="primary" dark @click.prevent="addGroup">
                                        <v-icon left large>mdi-arm-flex</v-icon>
                                        <div class="text-h5">搞掂開團</div>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-tab-item>

        </v-tabs>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
                groups: [],
                group: {type:'常規團', currency:'MOP', quota: 1000, suspend: false, delivery_time_text: '截單日後2-3星期'},
                type_text: ['常規團', '快閃團', '特價團', '清貨團'],
                currency_text: ['MOP', 'HKD', 'RMB'],
                singleSelect: false,
                date: '',
                selected_items: [],
                snack: false,
                snackColor: '',
                snackText: '',
                selected_payment: [],
                selected_delivery: [],
                selected_files: [],
                item_headers: [
                    {text: 'Full Item Code', value: 'full_item_code'},
                    {text: 'Item Name', value: 'item_name'},
                    {text: 'Att', value: 'att'},
                    {text: 'Price', value: 'price'},
                    {text: 'Qty', value: 'qty'},
                ],
                items: [],
                find_item_code: '',
                payment_headers: [
                    {text: 'ID', value: 'id'},
                    {text: 'Payment Method', value: 'name'},
                ],
                payments: [],
                delivery_headers: [
                    {text: 'ID', value: 'id'},
                    {text: 'Delivery Method', value: 'name'},
                ],
                deliverys: [],
                error: false,
                error_message: [],
                group_pass : false,
                checkout_site: this.$store.state.checkout_site,
                customer_site: this.$store.state.customer_site,
            }
        },
        created() {
            this.axios
                .get('/api/payment_list')
                .then(response => {
                    this.payments = response.data.data;
                    this.selected_payment.push(this.payments[0]);
                });
            this.axios
                .get('/api/delivery_list')
                .then(response => {
                    this.deliverys = response.data.data;
                    this.selected_delivery.push(this.deliverys[0]);
                });
        },
        mounted() {

        },
        methods: {
            save() {
                this.snack = true;
                this.snackColor = 'success';
                this.snackText = 'Data saved';
            },
            cancel() {
                this.snack = true;
                this.snackColor = 'error';
                this.snackText = 'Canceled';
            },
            open() {
                this.snack = true;
                this.snackColor = 'info';
                this.snackText = 'Dialog opened';
            },
            close() {
                // console.log('Dialog closed')
            },
            findItem(find_item_code) {
                this.axios
                    .get(this.customer_site + '/api/item/' + find_item_code)
                    .then(response => {
                        this.items = response.data.data;
                    });
            },
            addItem() {
                let item = {
                    "full_item_code": Date.now(),
                    "item_name": "\u81a0\u888b",
                    "att": "WHT",
                    "price": 1,
                    "qty": "100"
                };
                this.items.push(item);
            },
            onFileSelected(event) {
                this.selected_files = event.target.files;
                console.log(this.selected_files);
            },
            checkGroup() {
                this.error = false;
                if (this.group.name == null || this.group.name == undefined || this.group.name == '') {
                    this.error = true;
                    this.error_message[0] = ['請填寫 Name'];
                }
                else if (this.group.start_date == null || this.group.start_date == undefined || this.group.start_date == '') {
                    this.error = true;
                    this.error_message[0] = ['請填寫 Start Date'];
                }
                else if (this.group.expiry_date == null || this.group.expiry_date == undefined || this.group.expiry_date == '') {
                    this.error = true;
                    this.error_message[0] = ['請填寫 Expiry Date'];
                }
                else if (this.selected_files.length == 0) {
                    this.error = true;
                    this.error_message[0] = ['請選擇上載圖片'];
                }
                else if (this.group.delivery_time_text == null || this.group.delivery_time_text == undefined || this.group.delivery_time_text == '') {
                    this.error = true;
                    this.error_message[0] = ['請填寫 Delivery Time Text'];
                }
                else if (this.group.description == null || this.group.description == undefined || this.group.description == '') {
                    this.error = true;
                    this.error_message[0] = ['請填寫 Description'];
                }
                else if (this.selected_items.length == 0) {
                    this.error = true;
                    this.error_message[0] = ['請選擇商品資料'];
                }
                else if (this.selected_payment.length == 0) {
                    this.error = true;
                    this.error_message[0] = ['請選擇 Payment method'];
                }
                else if (this.selected_delivery.length == 0) {
                    this.error = true;
                    this.error_message[0] = ['請選擇 Delivery Method'];
                }
                else {
                    this.error = false;
                    this.error_message[0] = '';
                    this.group_pass = true;
                }
            },
            addGroup() {
                this.checkGroup();
                if (this.group_pass) {
                    this.axios
                        .post('/api/group', this.group)
                        .then(response => {
                            const formData = new FormData();
                            if (!this.selected_files.length) return;
                            for (var i = 0; i < this.selected_files.length; i++) {
                                let file = this.selected_files[i];
                                formData.append('images[' + i + ']', file);
                            }
                            formData.set('group_id', response.data.data.group_id);
                            console.log(formData);
                            this.axios.post(this.checkout_site+'/api/group_photo', formData, {headers: {'content-type': 'multipart/form-data'}})
                                .then(response => {
                                    console.log(response);
                                })
                                .catch((error) => {
                                    if (error.response) {
                                        this.error_message = error.response.data.errors;
                                        this.error = true;
                                        console.log(error.response);
                                    }
                                });
                            for (let i = 0; i < this.selected_payment.length; i++) {
                                this.selected_payment[i].group_id = response.data.data.group_id;
                                this.axios
                                    .post('/api/group_payment', this.selected_payment[i]);
                            }
                            for (let i = 0; i < this.selected_delivery.length; i++) {
                                this.selected_delivery[i].group_id = response.data.data.group_id;
                                this.axios
                                    .post('/api/group_delivery', this.selected_delivery[i]);
                            }
                            for (let i = 0; i < this.selected_items.length; i++) {
                                this.selected_items[i].group_id = response.data.data.group_id;
                                this.axios
                                    .post('/api/group_product', this.selected_items[i])
                                    .then(response => {
                                        console.log(response);
                                    });
                            }
                            console.log(response);
                            this.$router.push({name: 'GroupNonExpired'});
                        })
                        .catch((error) => {
                            if (error.response) {
                                this.error_message = error.response.data.errors;
                                this.error = true;
                                console.log(error.response);
                            }
                        });
                }

            }
        }
    }
</script>