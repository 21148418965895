var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('div',{staticClass:"text-h4 px-4"},[_vm._v("所有團管理")]),_c('v-btn',{staticClass:"mx-4 text-h4",attrs:{"color":"primary","dark":"","x-large":"","router":"","to":{name:'AddGroup'}}},[_vm._v("我要開新團")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.groups,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading... Please wait","show-expand":"","options":_vm.options,"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-chevron-double-left',
            lastIcon: 'mdi-chevron-double-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus'
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.suspend",fn:function(ref){
        var item = ref.item;
return [(item.suspend)?_c('v-chip',{staticClass:"ma-2 text-h6",attrs:{"color":"red","label":"","dark":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cart-off")]),_vm._v(" 停團 ")],1):_c('v-chip',{staticClass:"ma-2 text-h6",attrs:{"color":"green","label":"","dark":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cart")]),_vm._v(" 開團中 ")],1)]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{staticClass:"text-subtitle-1 pa-4",attrs:{"colspan":headers.length}},[_vm._v(" Description: "+_vm._s(item.description)),_c('br'),_vm._v(" Currency: "+_vm._s(item.currency)),_c('br'),_vm._v(" Remarks: "+_vm._s(item.remarks)),_c('br'),_vm._v(" "+_vm._s(_vm.checkout_site)+"/group/"+_vm._s(item.id)),_c('br')])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-4",attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.editGroup(item.id)}}},[_vm._v(" mdi-file-document-edit-outline ")]),_c('v-icon',{staticClass:"mr-4",attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.editGroupPhoto(item.id)}}},[_vm._v(" mdi-image ")]),_c('v-icon',{staticClass:"mr-4",attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.showOneGroup(item.id)}}},[_vm._v(" mdi-file-document ")]),_c('v-icon',{staticClass:"mr-4",attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.showTran(item.id)}}},[_vm._v(" mdi-currency-usd ")]),_c('v-icon',{staticClass:"mr-4",attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.addTran(item.id)}}},[_vm._v(" mdi-basket-plus ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }