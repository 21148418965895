<template>
    <v-container>
    <v-row>
        <v-col cols="12" md="12">
            <template v-if="error">
                <v-alert
                        border="bottom"
                        color="pink darken-1"
                        dark
                >
                    <div style="white-space:pre-wrap;" v-for="item in error_message" :key="item.message">{{ item[0] }}<br>
                    </div>
                </v-alert>
            </template>
            <v-card
                    class="mx-auto my-4"
            >
                <v-card-title>
                    <div class="text-h4 mx-4">新增管理員</div>
                </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                            <tr>
                                <td width="20%"><div class="text-h6">Name</div></td>
                                <td><div class="text-h6">
                                    <v-text-field
                                            outlined
                                            label="Name"
                                            prepend-inner-icon="mdi-cellphone-iphone"
                                            v-model="admin.name"
                                            class="mt-4"
                                    ></v-text-field></div>
                                </td>
                            </tr>
                            <tr>
                                <td width="20%"><div class="text-h6">Email</div></td>
                                <td><div class="text-h6">
                                    <v-text-field
                                            outlined
                                            label="Email"
                                            prepend-inner-icon="mdi-cellphone-iphone"
                                            v-model="admin.email"
                                            class="mt-4"
                                    ></v-text-field></div>
                                </td>
                            </tr>
                            <tr>
                                <td width="20%"><div class="text-h6">Password</div></td>
                                <td><div class="text-h6">
                                    <v-text-field
                                            type="password"
                                            outlined
                                            label="Password"
                                            prepend-inner-icon="mdi-cellphone-iphone"
                                            v-model="admin.password"
                                            class="mt-4"
                                    ></v-text-field></div>
                                </td>
                            </tr>
                            <tr>
                                <td width="20%"><div class="text-h6">Password Confirmation</div></td>
                                <td><div class="text-h6">
                                    <v-text-field
                                            type="password"
                                            outlined
                                            label="Password Confirmation"
                                            prepend-inner-icon="mdi-cellphone-iphone"
                                            v-model="admin.password_confirmation"
                                            class="mt-4"
                                    ></v-text-field></div>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><v-btn x-large color="primary" class="ma-4 float-right" dark @click.prevent="addAdmin"><div class="text-h5">新增管理員</div></v-btn></td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                admin: {},
                error: false,
                error_message: '',
            }
        },
        created() {

        },
        methods: {
            addAdmin() {
                this.axios
                    .post("/api/register", this.admin)
                    .then((response) => {
                        console.log(response.data.data);
                        this.$router.push({name: 'Home'});
                    })
                    .catch((error) => {
                        if (error.response) {
                            this.error_message = error.response.data.errors;
                            this.error = true;
                            console.log(error.response);
                        }
                    });
            },
        }
    }
</script>
