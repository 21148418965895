var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('div',{staticClass:"text-h4 px-4"},[_vm._v("黑名單客人")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.customers,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading... Please wait","options":_vm.options,"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-chevron-double-left',
            lastIcon: 'mdi-chevron-double-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus'
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.is_active",fn:function(ref){
        var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":"","color":"green darken-3"},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}})]}},{key:"item.has_password",fn:function(ref){
        var item = ref.item;
return [(item.has_password === '1')?_c('v-chip',{staticClass:"ma-4",attrs:{"color":"green","text-color":"white"}},[_c('div',{staticClass:"text-h5"},[_vm._v("有設定")])]):_c('v-chip',{staticClass:"ma-4",attrs:{"color":"red","text-color":"white"}},[_c('div',{staticClass:"text-h5"},[_vm._v("未設定")])])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-8",attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.editcustomer(item.id)}}},[_vm._v(" mdi-file-document-edit-outline ")]),_c('v-icon',{staticClass:"mr-8",attrs:{"large":"","color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.showTran(item.mobile)}}},[_vm._v(" mdi-currency-usd ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }