<template>
    <v-container>
    <v-row>
        <v-col cols="12" md="12">
            <v-card
                    class="mx-auto my-4"
            >
                <v-card-title>
                    <div class="text-h4 mx-4">編輯訂購申請 (Group Ask ID: {{ group_ask.id }}, Group ID: {{ group_ask.group_id }})</div>
                    <v-btn x-large color="primary" class="ma-4" dark @click.prevent="updateUser"><div class="text-h5">儲存更新</div></v-btn>
                    <v-btn x-large color="primary" class="ma-4" dark @click.prevent="back"><div class="text-h5">返回</div></v-btn>
                </v-card-title>

                <v-card-text class="mb-n6">
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                            <tr>
                                <td width="20%"><div class="text-h6">Create Date</div></td>
                                <td><div class="text-h6">{{ group_ask.created_at }}</div></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">Group Name</div></td>
                                <td><div class="text-h6">{{ group_ask.group_name }}</div></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">Mobile</div></td>
                                <td><div class="text-h6">{{ group_ask.mobile }}</div></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">Updated By</div></td>
                                <td><div class="text-h6">{{ group_ask.updated_by }}</div></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">Updated At</div></td>
                                <td><div class="text-h6">{{ group_ask.updated_at }}</div></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">Closed</div></td>
                                <td><v-switch v-model="group_ask.is_closed" color="green darken-3"></v-switch></td>
                            </tr>
                            <tr>
                                <td><div class="text-h6">Remarks (Internal only)</div></td>
                                <td><div class="text-h6 mt-4"><v-textarea
                                        solo
                                        name="input-7-4"
                                        label="Remarks"
                                        v-model="group_ask.remarks"
                                ></v-textarea></div></td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                group_ask: [],
            }
        },
        created() {
            this.axios
                .get("/api/group_ask/"+this.$route.params.id)
                .then(response => {
                    this.group_ask = response.data.data[0];
                });
        },
        methods: {
            back() {
                this.$router.go(-1);
            },
            updateUser() {
                this.axios
                    .put("/api/group_ask/"+this.$route.params.id, this.group_ask)
                    .then((response) => {
                        this.$router.push({name: 'AllGroupAsk'});
                        console.log(response);
                    });
            }
        }
    }
</script>