import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
// Axios.defaults.headers.common["Authorization"] = "Bearer " + token;
export default new Vuex.Store({
  state: {
      count: 0,
      baseURL: '',
      islogin: false,
      username: 'guest',
      user_id: '',
      token: '',
      checkout_site: 'https://checkout.heapsstore.com',
      customer_site: 'https://customer.heapsstore.com',
  },
  mutations: {
      increment (state) {
          state.count++
      }
  },
  actions: {
  },
  modules: {
  }
})
