<template>
    <v-card>
        <v-card-title>
            <div class="text-h4 px-4">訂單管理 (所有訂單)</div>
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
            ></v-text-field>
        </v-card-title>
    <v-data-table
            :headers="headers"
            :items="trans"
            :search="search"
            class="elevation-1"
            :loading="loading"
            loading-text="Loading... Please wait"
            show-expand
            :options.sync="options"
            :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-chevron-double-left',
                lastIcon: 'mdi-chevron-double-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus'
            }"
    >
        <template v-slot:item.is_notice="{ item }">
            <v-switch disabled v-model="item.is_notice" color="green darken-3"></v-switch>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="text-subtitle-1 pa-4">
                Amount: {{ item.currency }} {{ item.amount }}<br>
                Delivery: {{ item.delivery_list_name }}<br>
                Remarks: {{ item.remarks }}<br>
            </td>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon
                    large
                    class="mr-8"
                    @click="editTran(item.id)"
                    color="primary"
            >
                mdi-file-document-edit-outline
            </v-icon>
            <v-icon
                    large
                    class="mr-8"
                    color="primary"
                    @click.prevent="showOneTran(item.id)"
            >
                mdi-file-document
            </v-icon>
        </template>
    </v-data-table>
    </v-card>
</template>

<script>
    export default {
        data () {
            return {
                loading: true,
                search: '',
                options: {
                    itemsPerPage: 10,
                },
                headers: [
                    { text: 'Tran ID', value: 'id', class: 'text-subtitle-1' },
                    { text: 'Invoice', value: 'invoice', class: 'text-subtitle-1' },
                    { text: 'Order Date', value: 'created_at', class: 'text-subtitle-1' },
                    { text: 'Group Name', value: 'group_name', class: 'text-subtitle-1' },
                    { text: 'Mobile', value: 'mobile', class: 'text-subtitle-1' },
                    { text: 'Amount', value: 'amount', class: 'text-subtitle-1' },
                    { text: 'Payment Method', value: 'payment_list_name', class: 'text-subtitle-1' },
                    { text: 'Tran Status', value: 'transaction_status_list_name', class: 'text-subtitle-1' },
                    { text: 'Notice', value: 'is_notice', class: 'text-subtitle-1' },
                    { text: 'Actions', value: 'actions', class: 'text-subtitle-1', sortable: false },
                ],
                trans: [],
            }
        },
        created() {
            this.axios
                .get('/api/tran')
                .then(response => {
                    this.loading = false;
                    this.trans = response.data.data;
                });
        },
        methods: {
            editTran(id) {
                this.$router.push('/tran/'+id+'/edit');
            },
            showOneTran(id) {
                this.$router.push('/tran/'+id);
            }
        }
    }
</script>