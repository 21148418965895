<template>
    <v-card>
        <v-card-text>
            <v-form>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="8">
                            <h2>Group ID: {{ group.id }}
                                <v-chip v-if="group.suspend" class="ma-4" color="pink" text-color="white">
                                    <div class="text-h5">停團</div>
                                    <v-avatar right class="pink darken-2">
                                        T
                                    </v-avatar>
                                </v-chip>
                                <v-chip v-else class="ma-4" color="green" text-color="white">
                                    <div class="text-h5">開團中</div>
                                    <v-avatar right class="green darken-4">
                                        F
                                    </v-avatar>
                                </v-chip>
                                <br> {{ group.name }} - {{group.type}} ({{group.quota}})
                            </h2>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-btn x-large color="primary" class="mx-4" dark @click.prevent="edit(group.id)"><div class="text-h5">編輯資料</div></v-btn>
                            <v-btn x-large color="primary" class="mx-4" dark @click.prevent="back"><div class="text-h5">返回</div></v-btn>
                        </v-col>
                    </v-row>
                    <v-card class="py-4 my-4">
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                            <tr>
                                <td width="10%">Updated By:</td>
                                <td>{{ group.updated_by }} , {{ group.updated_at }}</td>
                            </tr>
                            <tr>
                                <td>Strat Date:</td>
                                <td>{{group.start_date}}</td>
                            </tr>
                            <tr>
                                <td>Expiry Date:</td>
                                <td>{{group.expiry_date}}</td>
                            </tr>
                            <tr>
                                <td>Description:</td>
                                <td><div v-html="group.description"></div></td>
                            </tr>
                            <tr>
                                <td>Remarks:</td>
                                <td><div v-html="group.remarks"></div></td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    </v-card>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-card>
                                <v-chip class="ma-4" color="green" text-color="white">
                                    <div class="text-h5">本團商品</div>
                                    <v-avatar right class="green darken-4">
                                        {{ products.length }}
                                    </v-avatar>
                                </v-chip>
                                <div class="pa-4">
                                    <ul>
                                        <li class="text-h5" v-for="item1 in products" :key="item1.full_item_code"> {{ item1.full_item_code }} | {{ item1.item_name}} | {{ item1.att }} | {{ group.currency }} {{ item1.price }} | {{ item1.qty }}</li>
                                    </ul>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-card>
                                <v-chip class="ma-4" color="green" text-color="white">
                                    <div class="text-h5">本團支付方式</div>
                                    <v-avatar right class="green darken-4">
                                        {{ payments.length }}
                                    </v-avatar>
                                </v-chip>
                                <div class="pa-4">
                                    <ul>
                                        <li class="text-h5" v-for="item2 in payments" :key="item2.id"> {{ item2.name}} </li>
                                    </ul>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card>
                                <v-chip class="ma-4" color="green" text-color="white">
                                    <div class="text-h5">本團配送方式</div>
                                    <v-avatar right class="green darken-4">
                                        {{ deliverys.length }}
                                    </v-avatar>
                                </v-chip>
                                <div class="pa-4">
                                    <ul>
                                        <li class="text-h5" v-for="item3 in deliverys" :key="item3.id"> {{ item3.name}} </li>
                                    </ul>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
                group: [],
                products: [],
                payments: [],
                deliverys: [],
            }
        },
        created() {
            // if (Vue.prototype.$islogin){
                this.axios
                    .get("/api/group/"+this.$route.params.id)
                    .then((response) => {
                        this.group = response.data.data[0];
                    });
                this.axios
                    .get("/api/group_product/"+this.$route.params.id+"/search_by_group_id")
                    .then((response) => {
                        this.products = response.data.data;
                    });
                this.axios
                    .get("/api/group_payment/"+this.$route.params.id+"/search_by_group_id")
                    .then((response) => {
                        this.payments = response.data.data;
                    });
                this.axios
                    .get("/api/group_delivery/"+this.$route.params.id+"/search_by_group_id")
                    .then((response) => {
                        this.deliverys = response.data.data;
                    });
            // }
            // else{
            //     this.$router.push(Vue.prototype.$loginpage);
            // }
        },
        methods: {
            back() {
                this.$router.go(-1);
            },
            edit(id) {
                this.$router.push('/group/'+id+'/edit');
            }
        },
        // computed: {
        //     now: function () {
        //         return Date.now().toLocaleString();
        //     },
        // }
    }
</script>