<template>
    <!-- App.vue -->
    <v-app>
        <v-navigation-drawer v-model="drawer" enable-resize-watcher app dark bottom temporary>
            <!-- -->
            <v-card>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="title">
                            Order System
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            Version: 1.2.0<br>
                            {{ this.$store.state.username }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list>
                    <v-list-group
                            v-for="item in items"
                            :key="item.title"
                            v-model="item.active"
                            :prepend-icon="item.action"
                            no-action
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.title"></v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item
                                v-for="subItem in item.items"
                                :key="subItem.title"
                                router
                                :to="subItem.link"

                        >
                            <v-list-item-content>
                                <v-list-item-title v-text="subItem.title"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </v-list>

            </v-card>
        </v-navigation-drawer>

        <v-app-bar
                app
                color="green darken-2"
                dark
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
                <span class="hidden-sm-and-down">訂單管理系統</span>
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <router-link to="/tran/input/input_search_tran_by_mobile">
                <v-btn icon>
                    <v-icon color="white">mdi-magnify</v-icon>
                </v-btn>
            </router-link>
            <router-link to="/dashboard">
                <v-btn icon>
                    <v-icon color="white">mdi-view-dashboard</v-icon>
                </v-btn>
            </router-link>
            <router-link to="/dashboard_order">
                <v-btn icon>
                    <v-icon color="white">mdi-chart-line</v-icon>
                </v-btn>
            </router-link>
            <router-link to="/logout">
                <v-btn icon>
                    <v-icon color="white">mdi-logout</v-icon>
                </v-btn>
            </router-link>
        </v-app-bar>

        <!-- Sizes your content based upon application components -->
        <v-main>
            <!-- Provides the application the proper gutter container / content-->
            <!-- <v-content> -->
            <!-- If using vue-router -->
            <router-view></router-view>
            <!-- </v-content> -->
        </v-main>

        <v-footer height="auto" color="#212121" dark app>
            <v-layout justify-center row wrap>
                <v-flex dark py-3 pr-6 white--text xs12 text-right>
                    &copy;2022 — <strong>i2828 Technology Limited</strong>
                </v-flex>
            </v-layout>
        </v-footer>
    </v-app>
</template>

<script>
    export default {
        data() {
            return {
                drawer: false,
                items: [
                    {
                        action: 'mdi-cart mdi-36px',
                        title: '團管理',
                        items: [
                            {title: '開新團', link: '/group/add'},
                            {title: '有效團管理', link: '/group/non_expired'},
                            {title: '所有團管理', link: '/group/all'},
                        ],
                    },
                    {
                        action: 'mdi-currency-usd mdi-36px',
                        title: '訂單管理',
                        items: [
                            { title: '待處理訂單', link: '/tran/3/search_tran_by_status' },
                            { title: '等待客人轉帳', link: '/tran/1/search_tran_by_status' },
                            { title: '等待確認收款', link: '/tran/2/search_tran_by_status' },
                            { title: '近三個月訂單', link:'/tran/search_tran_by_3_month' },
                            { title: '所有訂單', link: '/tran/alltran' },
                            { title: '未跟進訂購申請', link: '/group_ask/not_yet_closed'},
                            { title: '所有訂購申請', link: '/group_ask/all'},
                        ],
                    },
                    {
                        action: 'mdi-magnify mdi-36px',
                        title: '查詢訂單',
                        items: [
                            { title: '客人電話', link: '/tran/input/input_search_tran_by_mobile' },
                            { title: '商品編號', link: '/tran/input/input_search_tran_by_itemcode' },
                            { title: '商品名稱', link: '/tran/input/input_search_tran_by_itemname' },
                            { title: '訂單狀態', link: '/tran/input/input_search_tran_by_status' },
                            { title: '訂單備註', link: '/tran/input/input_search_tran_by_remarks' },
                            { title: '商品備註', link: '/tran/input/input_search_tran_by_product_remarks' },
                            { title: '收帳單編號', link: '/tran/input/input_search_tran_by_invoice' },
                        ],
                    },
                    {
                        action: 'mdi-account-group mdi-36px',
                        title: '客戶管理',
                        items: [
                            { title: '顯示所有客人', link: '/customer/all' },
                            { title: '查詢客人（電話）', link: '/customer/search_customer_by_mobile'},
                            { title: 'VIP客人', link: '/customer/vipcustomer' },
                            { title: '黑名單客人', link: '/customer/blacklistcustomer' },
                            { title: '新增客人', link: '/customer/addcustomer' },
                        ],
                    },
                    {
                        action: 'mdi-account-circle mdi-36px',
                        title: '管理員',
                        items: [
                            { title: '登入', link: '/login' },
                            { title: '登出', link: '/logout' },
                            { title: '新增管理員', link: '/admin/add' },
                        ],
                    },
                ],
            }
        },

    }
</script>

