<template>
    <v-card>
        <v-card-text>
                <v-row>
                    <v-col cols="12" md="3">
                        <v-text-field
                                label="Mobile"
                                weith="100px"
                                v-model="mobile"
                        >
                            <v-icon slot="append" large color="primary" @click.prevent="findItem(mobile)">mdi-magnify</v-icon>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-data-table
                                :headers="headers"
                                :items="customers"
                                item-key="id"
                                class="elevation-0"
                        >
                            <template v-slot:item.is_active="{ item }">
                                <v-switch disabled v-model="item.is_active" color="green darken-3"></v-switch>
                            </template>
                            <template v-slot:item.has_password="{ item }">
                                <v-chip label dark color="green" text-color="white" v-if="item.has_password === '1'">
                                    <div class="text-h6">已設定</div>
                                </v-chip>
                                <v-chip label dark color="red" text-color="white" v-else>
                                    <div class="text-h6">未設定</div>
                                </v-chip>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon
                                        large
                                        class="mr-8"
                                        @click="editcustomer(item.id)"
                                        color="primary"
                                >
                                    mdi-file-document-edit-outline
                                </v-icon>
                                <v-icon
                                        large
                                        class="mr-8"
                                        color="primary"
                                        @click.prevent="showTran(item.mobile)"
                                >
                                    mdi-currency-usd
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
                loading: true,
                mobile: '',
                options: {
                    itemsPerPage: 5,
                },
                headers: [
                    { text: 'Customer ID', value: 'id', class: 'text-subtitle-1' },
                    { text: 'Mobile', value: 'mobile', class: 'text-subtitle-1' },
                    { text: 'Password', value: 'has_password', class: 'text-subtitle-1' },
                    { text: 'SMS Code', value: 'smscode', class: 'text-subtitle-1' },
                    { text: 'Name', value: 'name', class: 'text-subtitle-1' },
                    { text: 'Sex', value: 'sex', class: 'text-subtitle-1' },
                    { text: 'Email', value: 'email', class: 'text-subtitle-1' },
                    { text: 'FB Name', value: 'fb_full_name', class: 'text-subtitle-1' },
                    { text: 'FB UID', value: 'fb_uid', class: 'text-subtitle-1' },
                    { text: 'Remarks', value: 'remarks', class: 'text-subtitle-1' },
                    { text: 'Active', value: 'is_active', class: 'text-subtitle-1' },
                    { text: 'Discount', value: 'discount', class: 'text-subtitle-1' },
                    { text: 'Type', value: 'type_name', class: 'text-subtitle-1' },
                    { text: 'Actions', value: 'actions', class: 'text-subtitle-1', sortable: false },
                ],
                customers: [],
            }
        },
        created() {
        },
        methods: {
            findItem(mobile) {
                if (mobile!==''){
                    this.axios
                        .get('/api/customer/'+mobile+'/search_customer_by_mobile')
                        .then(response => {
                            this.loading = false;
                            this.customers = response.data.data;
                        });
                }
            },
            editcustomer(id) {
                this.$router.push('/customer/'+id+'/edit');
            },
            showTran(mobile) {
                this.$router.push('/tran/'+mobile+'/search_tran_by_mobile/');
            }
        }
    }
</script>